import { Info } from '@mui/icons-material';
import React from 'react';

const SelectCompanyToggler = ({isExistingCompany,setIsExistingCompany,setPartnerObj}) => {

    return (
        <div
            className="br50px df aic jcsb"
            style={{
                padding: "4px",
                width: "350px",
                backgroundColor: '#D2D2D299',
                margin: "auto",
                marginBottom: "2rem"
            }}
        >
            <div
                className={`br50px fs0-75rem pl0-5rem pr0-5rem pt0-5rem pb0-5rem w50 tac cp ${isExistingCompany ? "bg1 shadow" : ""}`}
                style={{ color: "#151313B2" }}
                onClick={() => {
                    setIsExistingCompany(true);
                    setPartnerObj({ loanDate: new Date(), companyEmail: '', checklistPurpose: '' });
                  }}
                              >
                Select Existing
            </div>
            <div
                className={`br50px fs0-75rem pl0-5rem pr0-5rem pt0-5rem pb0-5rem w50 tac tac cp ${!isExistingCompany ? "bg1 shadow" : ""}`}
                style={{ color: "#1513134C" }}
                onClick={() => {
                    setIsExistingCompany(false);
                    setPartnerObj({ loanDate: new Date(), companyEmail: '', checklistPurpose: '' });
                  }}            >
                Add New
            </div>
        </div>
    );
};

export default SelectCompanyToggler;
