// Custom react select 
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        border: 'none',
        boxShadow: 'none',
        minHeight: 'initial',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.5rem',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menu: (provided, state) => ({
        ...provided,
        width: "96%",
        marginTop: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#fc7785" : state.isFocused ? "#fde3e6" : provided.backgroundColor,
        color: state.isSelected ? "white" : provided.color,
        ':active': {
            ...provided[':active'],
            backgroundColor: state.isSelected ? "#fc7785" : "#fde3e6",
        },
    }),
};

const addCompanyCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        border: '1px solid #DBDFEE',
        boxShadow: 'none',
        minHeight: 'initial',
        borderRadius: '50px',
        padding: '0px 2px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.5rem',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        fontSize: '13px',
    }),
    placeholder: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        color: '#151313'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        color: '#4A67FF',
        marginRight: '4px'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menu: (provided, state) => ({
        ...provided,
        width: "96%",
        marginTop: 0,
        zIndex: 9999,
        top: "80%",
        boxShadow: "0px 8px 14px #07070729",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#fc7785" : state.isFocused ? "#fde3e6" : provided.backgroundColor,
        color: state.isSelected ? "white" : "#151313B3",
        ':active': {
            ...provided[':active'],
            backgroundColor: state.isSelected ? "#fc7785" : "#fde3e6",
        },
        fontSize: "13px",
        fontWeight: 600,
        cursor: "pointer",
    }),
};

const hasAllDataRepoPermission = (userPermissions) => {
    if (!userPermissions) return false;
    return ["D001", "D002", "D003", "D004", "D005", "D006", "D007", "D008", "D009", "D010", "D011", "D012", "D013", "D014", "D015", "D016", "D017", "D018", "D019", "D020"].every(
        key => userPermissions[key] === true
    );
}

export { customStyles, addCompanyCustomStyles, hasAllDataRepoPermission }