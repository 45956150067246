import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setLogout } from "../../config";
import { toggleModal } from "../../reducers/modalReducer";


export default function Introduction() {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);
    const partner = useSelector(state => state.partner);


    useEffect(() => {
        localStorage.removeItem('isDemoChecklist');
        localStorage.setItem('isPartnerChecklist', true);
    }, []);

    const handleLetsGo = async () => {
        await localStorage.setItem('isPartnerChecklist', true);
        localStorage.removeItem('testType');
        dispatch(toggleModal({ open: 'addCompany' }));
    };

    return (
        <>{partner?.['type'] &&
            <div className='df fdc w100 partner-checklist-page'>
                <div class='df jcsb aic px4rem'>
                    <div class='df fdc'>
                        <p class='fs1-5rem fw300 w100'>{partner['type'] == 'Business for Sale' ? 'For Sellers' : 'For Companies'}</p>
                        <p class='fs2-5rem fw500 w100'>{partner['type'] == 'Business for Sale' ? <>Make Your Business More Attractive <br /> To Buyers & <span class='c4'>Sell More Quickly</span></> : 'Rejected for a Loan? Let’s Try To Fix It'}</p>
                    </div>
                    {partner['type'] == 'Business for Sale'
                        ? <img className="df w300px" src={require(`../../../images/partner-checklist/bfs-intro.png`)} alt="" />
                        : <img className="df w300px" src={require(`../../../images/partner-checklist/abf-intro.png`)} alt="" />
                    }
                </div>
                <div class="df jcc w100">
                    <div class='df fdc bg38 p3rem br20px'>
                        <h5 class='fs1-5rem fw400 c15 mb2rem'>How we work?</h5>
                        {partner['type'] == 'Business for Sale'
                            ? <img className="w100" src={require(`../../../images/partner-checklist/bfs-how-we-work.png`)} alt="" />
                            : <img className="w100" src={require(`../../../images/partner-checklist/abf-how-we-work.png`)} alt="" />
                        }
                    </div>
                </div>
                <div class='w100 df jcc my2rem'>
                    <button onClick={() => handleLetsGo()} className='df aic jcc cp shadow c1 fw400 fs1rem pr br38px w400px py1rem bg4 bg3-hover'>Let's Go! - It's FREE</button>
                </div>
                {partner['type'] == 'Business for Sale'
                    ? <p class='fs0-75rem fw300 c12'>*= Diligentsia provides tools, not financial advice, to help improve your business for sale. We may receive a flat fee from BFS upon eventual sale of your business.</p>
                    : <p class='fs0-75rem fw300 c12'>*= Diligentsia provides tools, not financial advice, to help improve your ABF loan application. We earn a flat fee per successful resubmission, not commission on loan amounts.</p>
                }
                <p class='fs0-75rem fw300 c12'>** = Your information is only shared with {partner['type'] == 'Business for Sale' ? <a class='c3' href='https://www.businessesforsale.com/' target="_blank">BusinessesForSale.com</a> : 'Alternative Business Funding Limited'} with your permission</p>
            </div>
        }</>
    )
}