
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLogout, showMessage } from '../../../config';
import KeyFeatureCard from "../../card/KeyFeatureCard";
import InvestorLeadMatching from "./InvestorLeadMatching";
import { toggleModal } from '../../../reducers/modalReducer';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import OpenSeeMoreLandingPageModal from "../../modals/openSeeMoreLandingPage";

const UpdatedKeyFeatureModule = ({ data, refs, subTitle }) => {

    const dispatch = useDispatch();
    const cardContainerRef = useRef(null);
    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);

    const [newData, setNewData] = useState(data);
    const [secondCoreModulesFreeSelected, setSecondCoreModulesFreeSelected] = useState({});
    const [coreModulesFreeSelected, setCoreModulesFreeSelected] = useState(modal.historyData);

    // Create an array of refs for each card element
    const cardRefs = useRef([]);

    // Add refs to each card element dynamically
    const addToRefs = (el) => {
        if (el && !cardRefs.current.includes(el)) {
            cardRefs.current.push(el);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInside = cardRefs.current.some(ref => ref && ref.contains(event.target));
            if (!isClickInside) {
                setSecondCoreModulesFreeSelected({});
                setCoreModulesFreeSelected({})
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1281) {
                const allTheFields = [
                    ...(data?.newModules || []),
                    ...(data?.secondRowModules || [])
                ];
                // Create a new object without mutating the original `data`
                const updatedData = { ...data };
                delete updatedData.secondRowModules;
                setNewData({
                    ...updatedData,
                    newModules: allTheFields
                });
            }
        };
        // Initial call to handleResize
        handleResize();
        // Add event listener on window resize
        window.addEventListener("resize", handleResize);
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [data]);

    const onModalClose = () => {
        dispatch(toggleModal({ open: '', historyData: null }));
        setCoreModulesFreeSelected({});
        setSecondCoreModulesFreeSelected({})
    };

    const handleDemoChecklist = async () => {
        if (user['email']) dispatch(toggleModal({ open: 'tryDemoChecklist' }));
        else window.location.href = '/demoChecklist';
    };

    const submitDemoChecklist = async () => {
        await setLogout();
        showMessage('You are logout, now you can try the demo checklist!');
        window.location.href = '/demoChecklist'
    };

    return (
        <>
            <div id="homeMainBanner" className="bg1 py4rem pr"
                style={{ backgroundImage: `url(${require('../../../../images/landingpage/bg-landing.png')})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'relative', overflow: 'hidden' }}>
                <div ref={refs?.current?.[5]} className={`tslp mt2rem op1 tty0`}>
                    <div className='fw500 df jcc fs2-5rem mb0-5rem'>
                        <span className='c4'>Key modules</span><span className='c15 ml0-5rem'>and features</span>
                    </div>
                    <h1 className='fw400 fs1rem df jcc c15'>
                        {subTitle ? subTitle : 'Reusable diligence - building your businesses stronger'}
                    </h1>
                    <img src={require('../../../../images/landingpage/blue-circle-cut.png')} className="pa blue-cricle-slider" style={{ width: '3%', top: '84px', right: '0' }} />
                </div>
                <div className="keyfeautre-container py3rem w100 df fww aic hero-saf pr mb4rem">
                    {newData?.['newModules'] &&
                        <div ref={addToRefs} className="w100">
                            <div className="keyfeature-box-grid keyfeature-box-vcs-new">
                                {newData?.['newModules'].map((modul, moduleIndex) => {
                                    return (
                                        <div key={`${moduleIndex}-module-123`} style={{ width: '100%', minHeight: "200px" }}
                                            className="cp w100 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr gg2rem fdc-xs bsbb messageIconPolygon mt4rem">
                                            {modul?.['isStar'] && <img className="pa" alt="star-img" src={require('../../../../images/landingpage/gold-star-icon.png')} style={{ width: modul?.['isTwoMinuteTestBtn'] ? '5%' : '8%', right: '8%' }} />}

                                            <div className='df aic features-icon customFeatureIcon pa shadow'
                                                onClick={() => setCoreModulesFreeSelected(modul)}>
                                                {modul?.icon}
                                            </div>
                                            <div className='df fw500 fs1-75rem c15 fdc jcsb aifs h100 w100'>
                                                <span className="aboutDescription xlmra"
                                                    onClick={() => setCoreModulesFreeSelected(modul)}>{modul?.title}
                                                </span>
                                                {modul?.['isTwoMinuteTestBtn'] &&
                                                    <Link to='/testPage'
                                                        className={`w100 fs1rem bg1 br35px pr cp c4 arrowBtnAnimation df jcsb aic py0-5rem px1-5rem bsbb my0-5rem xlmra`}
                                                        style={{ border: '1px solid #FC7785' }}>
                                                        <div className="df fdc aifs jcfs">
                                                            <p className="asfs">Free 2-Minute Test</p>
                                                            <p className="fs12px fw400 asfs">No registration or credit card required</p>
                                                        </div>
                                                        <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#FC7785' }}>
                                                            <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#FC7785' />
                                                        </svg>
                                                    </Link>
                                                }
                                            </div>
                                            <div
                                                className="bottom-area"
                                                onClick={() => {
                                                    setCoreModulesFreeSelected(modul)
                                                    // dispatch(toggleModal({ open: "openSeeMore", historyData: modul })) 
                                                }}
                                            >
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {coreModulesFreeSelected && Object.keys(coreModulesFreeSelected).length
                                ? <div className="keyfeature-box-appstore mt2rem br20px p2rem shadow pr">
                                    <div>
                                        <h1 className="fs1-25rem c56 w100 pl1rem">{coreModulesFreeSelected?.heading}</h1>
                                        <p className="subs-btn pl1rem c56 df aic fs1rem mb1rem">{coreModulesFreeSelected?.subHeading}</p>
                                    </div>
                                    <div className="px1rem">
                                        {coreModulesFreeSelected.modules && Object.keys(coreModulesFreeSelected.modules).length
                                            ? <>{coreModulesFreeSelected['modulesTitle'] && <h2 className="c59 mt2rem mb1rem">{coreModulesFreeSelected['modulesTitle']}</h2>}
                                                <div className="dg gtcr4 gg1-5rem sm-gtcr1">
                                                    {Object.values(coreModulesFreeSelected['modules']).map((min, ind) => {
                                                        return <div className="pr">
                                                            <KeyFeatureCard key={`landing-Page--asdsfv-carding-${ind}`} item={min} />
                                                            {min['isClarence'] && <div className='pa' style={{ top: "0px", right: "-204px", zIndex: 0, maxWidth: '216px' }}>
                                                                <img alt='clarenceLogo' style={{ maxHeight: "180px" }} src={require('../../../../images/landingpage/datarepoClearance.png')} />
                                                            </div>}
                                                        </div>
                                                    })}
                                                </div>
                                            </> : null}
                                        {coreModulesFreeSelected['proPlusAppStore'] && Object.keys(coreModulesFreeSelected['proPlusAppStore']).length
                                            ? <>{coreModulesFreeSelected['proPlusAppStoreTitle'] && <h2 className="c59 mt2rem mb1rem">{coreModulesFreeSelected['proPlusAppStoreTitle']}</h2>}
                                                <div className="dg gtcr4 gg1-5rem sm-gtcr1">
                                                    {Object.values(coreModulesFreeSelected['proPlusAppStore']).map((da, ind) => {
                                                        return <KeyFeatureCard key={`landing-Page-card-5151554415-${ind}`} item={da} />
                                                    })}
                                                </div>
                                            </> : null}
                                        {coreModulesFreeSelected['cardContent'] && Object.keys(coreModulesFreeSelected['cardContent']).length
                                            ? <div className='df w100 smfdrr bg1 pt3rem pb2rem px4rem bsbb br20px' >
                                                <div style={{ width: "52%", height: '100%', paddingRight: "3%" }}>
                                                    <div className='df w100 mb2rem aic'>
                                                        <div style={{ maxWidth: "50px" }} className="df aic">{coreModulesFreeSelected['cardContent']?.icon}</div>
                                                        <div className="w90 fw500 c15 pl1rem df aic fs1-5rem" style={{ lineHeight: '37.32px' }}>{coreModulesFreeSelected['cardContent']?.title}</div>
                                                    </div>
                                                    {/* custom-list */}
                                                    <ul className="mt0-5rem pl2rem">
                                                        {coreModulesFreeSelected['cardContent']?.points?.map((objpoints, index) => {
                                                            return (
                                                                <li className="df fdc c14 fs1rem fw400 w80" key={`${objpoints}-554554er5w4ef45df54r-${index}`}
                                                                    style={{ lineHeight: '24px', paddingTop: "12px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...coreModulesFreeSelected['cardContent']?.liStyle }}>
                                                                    <div className="pr">
                                                                        <div className="circle"></div>
                                                                        {objpoints}
                                                                    </div>
                                                                    <div>{coreModulesFreeSelected['cardContent']?.subMenu && coreModulesFreeSelected['cardContent']?.subMenu?.index === index &&
                                                                        coreModulesFreeSelected['cardContent']?.subMenu?.points?.map((str, i) => {
                                                                            return <div keys={`point-d-s-${i}`} className="fs1rem c14 fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        })}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    {coreModulesFreeSelected['cardContent']?.notePoint && <div className="mt1rem c15 op80" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{coreModulesFreeSelected['cardContent']?.notePoint}</div>}
                                                </div>
                                                <div className="df aic jce" style={{ width: '45%' }}>
                                                    <img src={coreModulesFreeSelected['cardContent']?.image} className="w100" style={{ objectFit: 'fill' }} />
                                                </div>
                                            </div>
                                            : null}

                                        {coreModulesFreeSelected['investerLeadMatchingCard'] ? <InvestorLeadMatching /> : null}
                                    </div>

                                    <div className="df jcc aic mt3rem mb1rem">
                                        <Link to='/testPage'
                                            //  onClick={() => dispatch(toggleModal({ open: "welcome2MinuteTest" }))}
                                            className={`w100 fs1rem bg4 br35px pr cp c1 arrowBtnAnimation df jcsb aic py0-5rem px1-5rem bsbb my0-5rem xlmra`} style={{ border: '1px solid #FC7785', maxWidth: '350px' }}>
                                            <div className="df fdc aifs jcfs">
                                                <p className="asfs">Free 2-Minute Test</p>
                                                <p className="fs12px fw400 asfs">No registration or credit card required</p>
                                            </div>
                                            <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#fff' }}>
                                                <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#fff' />
                                            </svg>
                                        </Link>
                                    </div>

                                    {(!coreModulesFreeSelected['cardContent'] || !coreModulesFreeSelected['hideIntelligentsia'])
                                        ? <div className='pa sm-pr intelligentsiaLandingPage' style={{ bottom: "-8px", right: "-36px", zIndex: 1, maxWidth: "350px" }}>
                                            <img src={require('../../../../images/landingpage/intelligentsia.png')} alt='clarenceLogo' style={{ maxWidth: "350px" }} />
                                        </div> : null
                                    }
                                </div> : null
                            }
                        </div>
                    }
                    {newData?.['secondRowModules'] &&
                        <div className="w100 " ref={addToRefs}>
                            <div className="keyfeature-box-grid keyfeature-box-vcs-new">
                                {newData?.['secondRowModules'].map((item, moduleIndex) => {
                                    return (
                                        <div
                                            key={`${moduleIndex}-sed-module-45645454555`}
                                            className="cp w100 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr gg2rem fdc-xs bsbb messageIconPolygon mt4rem"
                                            style={{ width: '100%', minHeight: "200px" }}
                                        // onMouseOver={() => { setSecondCoreModulesFreeSelected(item) }}
                                        // onMouseOut={() => { modal['open'] == '' && setSecondCoreModulesFreeSelected({}) }}
                                        >
                                            {item?.['isStar'] &&
                                                <img
                                                    src={require('../../../../images/landingpage/gold-star-icon.png')}
                                                    className="pa" alt="star-img"
                                                    style={{ width: item?.['isTwoMinuteTestBtn'] ? '5%' : '8%', right: '8%' }}
                                                />
                                            }
                                            <div className='df aic features-icon customFeatureIcon pa shadow'
                                                onClick={() => setSecondCoreModulesFreeSelected(item)}

                                            >
                                                {item?.icon}
                                            </div>
                                            <div className='df fw500 fs1-75rem c15 fdc jcsb aifs h100 w100'>
                                                <span className="aboutDescription xlmra"
                                                    onClick={() => setSecondCoreModulesFreeSelected(item)}
                                                >
                                                    {item?.title}
                                                </span>
                                                {/* {item?.['isTwoMinuteTestBtn'] &&
                                                    <Link to='/testPage'
                                                        // to={`${item?.hashTag ? `#${item?.hashTag}` : `#`}`}
                                                        // onClick={() => {
                                                        //     dispatch(toggleModal({ open: "welcome2MinuteTest" }))
                                                        //     item?.hashTag && localStorage.setItem('testType', item?.hashTag);
                                                        // }}
                                                        className={`w100 fs1rem bg1 br35px pr cp c4 arrowBtnAnimation df jcsb aic py0-5rem px1-5rem bsbb my0-5rem`}
                                                        style={{ border: '1px solid #FC7785' }}>
                                                        <div className="df fdc aifs jcfs">
                                                            <p className="asfs">Free 2-Minute Test</p>
                                                            <p className="fs12px fw400 asfs">No registration or credit card required</p>
                                                        </div>
                                                        <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#FC7785' }}>
                                                            <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#FC7785' />
                                                        </svg>
                                                    </Link>
                                                } */}
                                            </div>
                                            <div className="bottom-area" onClick={() => { setSecondCoreModulesFreeSelected(item) }}></div>
                                        </div>
                                    )
                                })}
                            </div>

                            {secondCoreModulesFreeSelected && Object.keys(secondCoreModulesFreeSelected).length
                                ? <div className="keyfeature-box-appstore mt2rem br20px p2rem shadow">
                                    <div>
                                        <h1 className="fs1-25rem c56 w100 pl1rem">{secondCoreModulesFreeSelected?.heading}</h1>
                                        <p className="subs-btn pl1rem c56 df aic fs1rem mb1rem">{secondCoreModulesFreeSelected?.subHeading}</p>
                                    </div>
                                    <div className="px1rem">
                                        {secondCoreModulesFreeSelected.modules && Object.keys(secondCoreModulesFreeSelected.modules).length
                                            ? <>{secondCoreModulesFreeSelected['modulesTitle'] && <h2 className="c59 mt2rem mb1rem">{secondCoreModulesFreeSelected['modulesTitle']}</h2>}
                                                <div className="dg gtcr4 gg1-5rem sm-gtcr1">
                                                    {Object.values(secondCoreModulesFreeSelected['modules']).map((item, ind) => {
                                                        return <div className="pr">
                                                            <KeyFeatureCard key={`landing--sdf-sd-Randon-Page-carding-${ind}`} item={item} />
                                                            {item['isClarence'] && <div className='pa' style={{ top: "0px", right: "-204px", zIndex: 0, maxWidth: '216px' }}>
                                                                <img src={require('../../../../images/landingpage/datarepoClearance.png')} alt='clarenceLogo' style={{ maxHeight: "180px" }} />
                                                            </div>
                                                            }
                                                        </div>
                                                    })}
                                                </div>
                                            </> : null
                                        }
                                        {secondCoreModulesFreeSelected['proPlusAppStore'] && Object.keys(secondCoreModulesFreeSelected['proPlusAppStore']).length
                                            ? <>{secondCoreModulesFreeSelected['proPlusAppStoreTitle'] && <h2 className="c59 mt2rem mb1rem">{secondCoreModulesFreeSelected['proPlusAppStoreTitle']}</h2>}
                                                <div className="dg gtcr4 gg1-5rem sm-gtcr1">
                                                    {Object.values(secondCoreModulesFreeSelected['proPlusAppStore']).map((item, ind) => {
                                                        return <KeyFeatureCard key={`landing-windwpw-Page-card-${ind}`} item={item} />
                                                    })}
                                                </div>
                                            </> : null
                                        }
                                        {secondCoreModulesFreeSelected['cardContent'] && Object.keys(secondCoreModulesFreeSelected['cardContent']).length
                                            ? <div className='df w100 smfdrr bg1 pt3rem pb2rem px4rem bsbb br20px' >
                                                <div style={{ width: "52%", height: '100%', paddingRight: "3%" }}>
                                                    <div className='df w100 mb2rem aic'>
                                                        <div style={{ maxWidth: "50px" }} className="df aic">{secondCoreModulesFreeSelected['cardContent']?.icon}</div>
                                                        <div className="w90 fw500 c15 pl1rem df aic fs1-5rem" style={{ lineHeight: '37.32px' }}>{secondCoreModulesFreeSelected['cardContent']?.title}</div>
                                                    </div>
                                                    {/* custom-list */}
                                                    <ul className="mt0-5rem pl2rem">
                                                        {secondCoreModulesFreeSelected['cardContent']?.points?.map((objpoints, index) => {
                                                            return (
                                                                <li key={`${objpoints}-845153sd84ClearDb-${index}`} className="df fdc c14 fs1rem fw400 w80"
                                                                    style={{ lineHeight: '24px', paddingTop: "12px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...secondCoreModulesFreeSelected['cardContent']?.liStyle }}>
                                                                    <div className="pr">
                                                                        <div className="circle"></div>
                                                                        {objpoints}
                                                                    </div>
                                                                    <div>{secondCoreModulesFreeSelected['cardContent']?.subMenu && secondCoreModulesFreeSelected['cardContent']?.subMenu?.index === index &&
                                                                        secondCoreModulesFreeSelected['cardContent']?.subMenu?.points?.map((str, i, arr) => {
                                                                            return <div key={`${i}-c${i}-c-v-${i}`} className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        })
                                                                    }
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul >
                                                    {secondCoreModulesFreeSelected['cardContent']?.notePoint &&
                                                        <div className="mt1rem c15 op80" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{secondCoreModulesFreeSelected['cardContent']?.notePoint}</div>
                                                    }
                                                </div>
                                                <div className="df aic jce" style={{ width: '45%' }}>
                                                    <img src={secondCoreModulesFreeSelected['cardContent']?.image} className="w100" style={{ objectFit: 'fill' }} />
                                                </div>
                                            </div>
                                            : null
                                        }

                                        {secondCoreModulesFreeSelected['investerLeadMatchingCard'] ? <InvestorLeadMatching /> : null}
                                    </div>

                                    <div className="df jcc aic mt3rem mb1rem">
                                        <Link to='/testPage'
                                            // onClick={() => dispatch(toggleModal({ open: "welcome2MinuteTest" }))}
                                            className={`w100 fs1rem bg4 br35px pr cp c1 arrowBtnAnimation df jcsb aic py0-5rem px1-5rem bsbb my0-5rem xlmra`} style={{ border: '1px solid #FC7785', maxWidth: '350px' }}>
                                            <div className="df fdc aifs jcfs">
                                                <p className="asfs">Free 2-Minute Test</p>
                                                <p className="fs12px fw400 asfs">No registration or credit card required</p>
                                            </div>
                                            <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#fff' }}>
                                                <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#fff' />
                                            </svg>
                                        </Link>
                                    </div>

                                    {(!secondCoreModulesFreeSelected['cardContent'] || !secondCoreModulesFreeSelected['hideIntelligentsia'])
                                        ? <div className='pa sm-pr intelligentsiaLandingPage' style={{ bottom: "40px", right: "-60px", zIndex: 1, maxWidth: "350px" }}>
                                            <img src={require('../../../../images/landingpage/intelligentsia.png')} alt='clarenceLogo' style={{ maxWidth: "350px" }} />
                                        </div> : null
                                    }

                                </div> : null
                            }
                        </div>
                    }

                    {!data['hideQuickTestBtn'] ? <div className="pr df w100 jcc mt2rem bottom-demo-btn">
                        <button onClick={() => { handleDemoChecklist() }} className="cp bg4 bg3-hover td250 br40px df jcsb aic pl2rem pr2rem c1 tal" style={{ paddingTop: "10px", paddingBottom: "10px", width: '357px' }}>
                            <div className="db">
                                Quick Demo - Try It Now!
                                <span className="c1 db w100" style={{ fontSize: "11px", lineHeight: "15px" }}>No registration or credit card required</span>
                            </div>
                            <svg width="20" height="17" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.57843 14.5715H21.6274L15.8315 21.1082C14.1667 22.9858 16.6639 25.8021 18.3287 23.9245L23.6072 17.9621L27.1308 13.9818C27.8138 13.207 27.8138 11.9557 27.1308 11.1809L18.3287 1.24207C17.9938 0.853956 17.5329 0.636189 17.0526 0.640694C15.4672 0.640919 14.6892 2.8187 15.8315 4.05856L21.6411 10.5953H2.48727C0.0456802 10.7319 0.227984 14.7086 2.57843 14.5715Z" fill="white"></path>
                            </svg>
                        </button>
                    </div> : null
                    }
                    {!data['hideQuickTestBtn'] &&
                        <div className="pa b0 r0 df jce bottom-right-ai-img" style={{ bottom: "-95px" }}>
                            <img className="w80" src={require('../../../../images/landingpage/quick-demo-vcs.png')} alt='quick-demo-vcs' />
                        </div>
                    }
                </div>
            </div>

            {/* Key Feature See More Popup */}
            <OpenSeeMoreLandingPageModal data={coreModulesFreeSelected} handleClose={onModalClose} />

            {/* Demo Checklist Popup */}
            <ModalWrapper modalType={"tryDemoChecklist"} modalCardClasses="maw800px">
                <>
                    <h2 className="c15 fw600 tac w100">Demo Checklist</h2>
                    <div className='df fdc jcc my1rem'>
                        <div className='tac'>
                            <p className='my0-5rem c15 op70 fs14px'>You have already registered. The demo checklist is a user flow that allows user to add a company, create a checklist, and then register if pleased. <br /> If you need to experience this flow, you will be logged out.</p>
                        </div>
                        <div className='df jcc mt1rem'>
                            <button onClick={() => submitDemoChecklist()} className="shadow bsbb jcc df fdc aic fw500  px1rem c1 bg4 br19px cp" style={{ width: '35%', paddingTop: '8px', paddingBottom: '8px' }}>
                                Continue
                            </button>
                        </div>
                    </div>
                </>
            </ModalWrapper>

        </>
    )
}

export default UpdatedKeyFeatureModule