import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import AuditLogIcon from "../../../../images/dataRepo/auditLog.svg"
import moment from 'moment';
import { AppMode } from '../../../env';
import Pill from '../../common/Pill';
import Delete from "../../../../images/dataRepo/delete.svg"

const TeamPermissionLogs = ({ modalType, logList, logModule, profileClass, getViewableURL }) => {


    const getLogsHTML = (logs, showDetails = false) => logs.filter(({ payload }) => payload.updateData).map((log, idx) => {
        const { payload: { updateData } } = log;
        console.log("updateData", updateData)
        return (
            <div key={`logSection${idx}`} className="df fdc my0-5rem">
                <div className='df aic'>
                    {log?.['userId']?.['profile']
                        && <img
                            className={`ass mr0-5rem br100 w2rem h2rem ${profileClass}`} src={log['userId']['profile']} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
                        || <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-5rem ${profileClass} client-profile`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
                    }
                    <p className='fs0-75rem c15 ml0-5rem df aic'>{log['message']} at
                        <span className='c36 op70' style={{ marginLeft: "6px" }}> {moment(log.timestamp).format("MMM D, YYYY, h:mmA")}</span>
                    </p>
                </div>
                <div className="w100 mt0-5rem">
                    <div
                        className="br10px p1rem mb0-5rem fs0-75rem"
                        style={{
                            backgroundColor: "#FC778533",
                            "grid-template-columns": "25% 75%"
                        }}
                    >
                        <div className="c2">
                            {updateData['message']}
                        </div>

                    </div>
                </div>
            </div>
        )
    });


    return (
        <>
            <ModalWrapper modalType={modalType}>
                <h1 className="c15 fs1-75rem fw600 tal df aic"> <AuditLogIcon className="mr1rem" /> Audit Log</h1>
                <h2 className="c3 fs0-75rem ttu w100 fw400 tal mb1rem mt0-5rem">Data Repository {logModule}  </h2>
                <div className="ma oya dg gg0-5rem mb2rem w100 lightScrollbar pr1rem" style={{
                    maxHeight: "400px",
                }}>
                    {logList.length > 0
                        ? getLogsHTML(logList, true)
                        : <h2 className="c4 fs1-5rem ttu w100 tac mb1rem">No Logs Found!!</h2>
                    }
                </div>
            </ModalWrapper>
        </>
    )
}

export default TeamPermissionLogs