import React from "react";
import moment from "moment";

export default function FreeKPI({ selectedCompany, moduleName, handleChecklistNext }) {

    return <>
        {selectedCompany && <>
            <table id="pdf" className="shadow w70 p2rem ma td250 dg gg2rem new-report df fdc">
                <div className="w100 df aic jcsb pdfHeaderCustom">
                    <div className="w50">
                        <h2 className="tal c15 lh1 mt1rem fs1-25rem ">KPI Report</h2>
                        <h3 className="fsi tal c15 fs1rem fw400 mb1rem">{selectedCompany['cName']}</h3>
                        <p className="mt3rem c15">Report Date: <span className="fw600">{moment(new Date()).format('MMM DD, YYYY')}</span></p>
                    </div>
                    <div className="w50 df fdc jcsb aife">
                        <img className="w80px h80px mb2rem" src={require("../../../images/bonus.svg")} alt="bonusIcon" />
                        <p className="c15 fs12px fw500 w100 tar">Bespoke KPI report for your company</p>
                    </div>
                </div>
                <>
                    <div className="bg52 br5px py0-5rem df aic jcsb mb1rem mt1rem">
                        <p className="miw8rem c1 ml1rem">#KPI Name</p>
                        <p className="miw8rem c1">KPI Description</p>
                        <p className="miw8rem c1">KPI Calculation</p>
                        <p className="miw8rem c1">KPI Metric</p>
                    </div>
                    {selectedCompany?.['keyPerformanceIndicators'] && selectedCompany['keyPerformanceIndicators'].map((kpi, idx) => (
                        <tr key={idx} style={{ verticalAlign: 'inherit' }}>
                            <td className="miw8rem tal c15 fs12px fw500 fsi"><b>{kpi['title'] || '-'}</b></td>
                            <td className="miw8rem tal c15 fs12px">{kpi['name'] || '-'}</td>
                            <td className="miw8rem tal c15 fs12px">{kpi['calculation'] || '-'}</td>
                            <td className="miw8rem tal c15 fs12px">{kpi['metric'] || '-'}</td>
                        </tr>
                    ))}
                </>
            </table>

            <div className='df aic w100 jcsb bsbb mt2rem'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>3/4 Section Review & Approval</p>
                <button onClick={() => handleChecklistNext(moduleName)} data-tooltip={moduleName ? 'Checklist Created! you can click Next to view checklist' : 'Your checklist creation is in progress, please wait!'}
                    className={`shadow c1 fw400 fs1rem pr br38px w300px py0-5rem df aic jcc ${moduleName ? 'bg4 bg3-hover cp' : 'bg16 cna'}`}>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>}
    </>;
} 