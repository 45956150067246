/* -- set app title --*/
const AppTitle = 'Frontend Diligentsia';

/* -- set app mode -- */
// const AppMode = 'demo';
// const AppMode = 'production';
const AppMode = 'development';

let URL, stripePK = 'pk_test_51MpurgLOHSjP8ChLmQdb5P3Ef1de2dt9dG9dFlZxFo9SI3d3LqahKDH2BGiRURNQ9swNjn0UyKSHxSYwik4QHibK00bllnaJh4';
switch (AppMode) {
    case 'development':
        URL = (location.host.match(/localhost:\d+/) ? 'http://localhost:4000' : "https://devserver.diligentsia.co.uk");
        break;
    case 'demo':
        URL = (location.host.match(/localhost:\d+/) ? 'http://localhost:8000' : "https://demoserver.diligentsia.co.uk");
        break;
    case 'production':
        URL = (location.host.match(/localhost:\d+/) ? 'http://localhost:4000' : "https://prodserver.diligentsia.co.uk");
        stripePK = "pk_live_51M6YZmLbuPrFlceS9gw3MoEgj37hepLFA0QujIYgoGxoGNhjEyfDoUTKDQZZo9x1ZSnZXyDwuPAGCF9o9F5RToWb00zH94XFiO";
        break;
    default:
        URL = "http://localhost:4000";
};

console.log('******** URL = ', URL);
console.log('******** AppMode = ', AppMode);

export { AppTitle, AppMode, URL, stripePK };