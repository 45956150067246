import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../config";

// Thunk to fetch categories and update state
export const fetchCategories = createAsyncThunk(
    "COMMON_SETTINGS/fetchCategories",
    async (_, { rejectWithValue }) => {
        try {
            const categoryResponse = await getRequest(`/admin/get/categorylist`);
            const catList = (categoryResponse?.body || []).map((obj) => ({
                ...obj,
                label: obj.name,
                value: obj._id,
            }));
            return [
                ...catList,
                // { label: "Other (please describe)", value: "other", hasAdd: true },
            ];
        } catch (error) {
            console.error("Error fetching categories:", error);
            return rejectWithValue(error.message);
        }
    }
);

// Reducer slice
const commonSlice = createSlice({
    name: "COMMON_SETTINGS",
    initialState: {
        categories: [],
        loading: false,
        error: null,
    },
    reducers: {
        UPDATE_CATEGORIES: (state, action) => {
            return { ...state, categories: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { UPDATE_CATEGORIES } = commonSlice.actions;
export default commonSlice.reducer;
