import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest, showMessage } from '../../../../config.js';
import { setCompany } from "../../../../reducers/companyReducer.js";
import { toggleModal } from "../../../../reducers/modalReducer.js";
import AuditLogIcon from "../../../../../images/dataRepo/auditLog.svg"
import TeamPermissionLogs from "../../../../components/modals/teamPermissionLogs/teamPermissionLogs.jsx";

export default function TeamPermissions({ company }) {

	const dispatch = useDispatch();
	let [groupedSections, setGroupedSections] = useState(null)
	let companyId = location.pathname.split("/").slice(-1)[0];
	const user = useSelector((state) => state.user);
	const [allLogs, setAllLogs] = useState([]);

	function capitalizeFirstLetter(val) {
		return String(val).charAt(0).toUpperCase() + String(val).slice(1);
	}

	const getViewableURL = (url) => {
		window.open(url, "_blank");
	};

	useEffect(() => {
		if (company['sections'] && company['sections'].length) {
			const _groupedSections = company['sections'].reduce((acc, section) => {
				const type = section.sectionNo.includes("UD0")
					? "Custom Section"
					: section.type && /extensions pack|extentions pack/i.test(section.type)
						? "Expension Pack"
						: section.type && section.type.includes("-")
							? section.type.split("-")[0].trim()
							: section.type;
				if (!acc[type]) acc[type] = [];
				acc[type].push(section);
				return acc;
			}, {});
			setGroupedSections(_groupedSections)
		}
	}, [company['sections']])

	const setPermissions = async (e, sectionNo, member) => {
		if (user['_id'] !== company['creatorId']['_id']) {
			e.preventDefault();
			return showMessage("Only admin can change permissions");
		}

		const action = e.target.checked ? "granted" : "revoked";

		let permissions = company?.['memberPermissions']?.[member['_id']];
		permissions = { ...permissions, [sectionNo]: e.target.checked };

		if (company?.['memberPermissions']?.[member['_id']]) {
			company['memberPermissions'] = { ...company['memberPermissions'], [member['_id']]: permissions };
			savePermissions(company, {
				message: user['firstName'] + " " + user['lastName'] + " " + action + " permission for " + sectionNo + " to " + member['firstName'] + " " + member['lastName'],
				userId: user['_id'],
				sectionNo,
				memberId: member['_id'],
				[member._id]: {
					[sectionNo]: e.target.checked
				}
			});
		}
	};

	const savePermissions = async (company, logData) => {
		// User Updated
		const res = await postRequest(`/company/updatePermission/${companyId}`, { permissions: company['memberPermissions'], updateData: logData });
		if (res['code'] == 200) dispatch(setCompany(company));
		showMessage(res['message']);
	};

	const getSectionTitle = (type) => {
		if (type === "existing sections") {
			return "Core Modules";
		} else if (type === "expension pack") {
			return "Expansion Pack"
		} else if (type === "extentions pack" || type === "custom section" || type === "additional sections") {
			return capitalizeFirstLetter(type);
		} else {
			return "";
		}
	};

	const getAuditLogs = async () => {
		const res = await postRequest(`/log`, { endpoint: `/api/company/updatePermission/${company['_id']}`, message: "Permissions updated" });
		console.log(res, "res")
		setAllLogs(res['body']);
		if (res["code"] == 200) {
			dispatch(toggleModal({ open: 'teamPermissionLogs' }))
		}
	};

	return (
		<>
			<div className="df jcfe aic">
				<div className="df bg1 br50px mr0-5rem bw2px bss pr1rem p0-5rem bsbb aic mr1rem cp add-btn-dashboard" onClick={getAuditLogs}>
					<span className='bg1 bsbb br100 w40px h40px tac df jcc aic'>
						<AuditLogIcon />
					</span>
					<span className='c15'>Audit Logs</span>
				</div>
			</div>
			<div className="sectionWrapper p1rem fs0-75rem pr mr2rem ml1rem pr mb2rem">
				<div className="table-permission">
					<table id="table" className="table-userPermissions table-clientPermissions 5">
						<thead className="ps t0">
							<tr>
								<th className="index-table">Modules</th>
								{company['creatorId'] &&
									<th className='user-area'>
										<div className="df jcc aic">
											<div className='mr1rem'>
												{company['creatorId']?.['profile']
													? <img data-tooltip={company['creatorId']?.firstName + " " + (company['creatorId']?.lastName && company['creatorId']?.lastName[0] ? company['creatorId']?.lastName[0] : "")} src={company['creatorId']?.['profile']} className={`shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31 `} />
													: <span data-tooltip={company['creatorId']?.firstName + " " + (company['creatorId']?.lastName && company['creatorId']?.lastName[0] ? company['creatorId']?.lastName[0] : "")} className={`shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31`}>
														{company['creatorId']?.firstName ? (company['creatorId']?.firstName[0] + (company['creatorId']?.lastName ? company['creatorId']?.lastName[0] : "")) : "?"}
													</span>
												}
											</div>
											<span>{company['creatorId']['firstName']} {company['creatorId']['lastName']} <br /> <span className="c4">Admin</span></span>
										</div>
										<div className='df jcc fdc'>
											<p className='fs0-75rem ml3rem'>Status: <span className='c7 mr0-5rem'>Activated </span></p>
											{company['creatorId']?.['lastLogin'] && <p className='fs0-75rem ml3rem'> Last Login: <span className={((new Date().getTime() - new Date(company['creatorId']['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(company['creatorId']['lastLogin']).format('ll')}</span></p>}
										</div>
									</th>
								}
								{company['members'] && company['members'].filter((mem) => mem._id !== company?.['creatorId']?.['_id']).map(member => {
									return (
										<th className='user-area'>
											<div className="df jcc aic cp" onClick={() => handleMemberEdit(member)}>
												<div className='mr1rem'>
													{member?.profile
														? <img data-tooltip={member?.firstName + " " + (member?.lastName && member?.lastName[0] ? member?.lastName[0] : '')} src={member?.profile} className="shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31" />
														: <span data-tooltip={member?.firstName + " " + (member?.lastName && member?.lastName[0] ? member?.lastName[0] : '')} className="shadow w2rem h2rem df jcc aic c0 br100 bss bow1px bo31">{member?.firstName ? (member?.firstName[0] + (member?.lastName ? member?.lastName[0] : "")) : "?"}</span>
													}
												</div>
												<span>{member['firstName']} {member['lastName']} <br /> {member['designation']}</span>
											</div>
											<div className='df jcc fdc fs0-75rem ml3rem'>
												<p>Status: <span className={`${member['emailVerified'] ? ' c7' : ' c8'} mr0-5rem`}>{member['emailVerified'] ? 'Activated' : <span>Invite Sent - ({moment(member['updatedAt']).format('ll')})</span>}</span></p>
												{member['emailVerified'] && <p> Last Login: <span className={((new Date().getTime() - new Date(member['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(member['lastLogin']).format('ll')}</span></p>}
											</div>
										</th>
									)
								})}
							</tr>
						</thead>
						<tbody>
							{
								groupedSections && Object.entries(groupedSections).map(([type, typeSections], inde) => {
									return (
										<>
											<tr key={`type-heading-${type}`}>
												<td style={{ paddingTop: "40px" }} colSpan={company['members'].length + 1} className="type-heading">
													<h3 style={{ borderTop: `${inde > 0 ? "1px solid rgb(219, 223, 238)" : "transparent"}`, paddingTop: "12px" }} className="c15">
														{getSectionTitle(type.toLowerCase())}
													</h3>
												</td>
											</tr>
											{typeSections.map((section, idx2) => {
												return (
													<tr key={`company['sections']-section-${idx2}`}>
														{/* Map Sections */}
														<td className='c15'>
															<span className='fw600'>{section['sectionNo']}</span> {section['sectionName']}
														</td>
														{/* Map Owner Permission */}
														<td className='c6 cp' key={idx2}>
															<label for={`owner-permission-${idx2}`} className="df jcc cp fs0-75rem">
																<span className='checkbox w2rem h2rem pr'>
																	<input disabled={true} defaultChecked={true} id={`permission-${idx2}`} className="shadow bw2px bss cp td250 w2rem h2rem br5px" type="checkbox" style={{ backgroundColor: '#8f8f8f', borderColor: '#8f8f8f' }} />
																</span>
															</label>
														</td>
														{/* Map Members Permission of that section */}
														{company['members'] && company['members'].filter((mem) => mem._id !== company?.['creatorId']?.['_id']).map((member, idx) => {
															// console.log(user, "userId")
															return (
																<td className='c6 cp' key={idx}>
																	<label for={`member-permission-${idx}`} className="df jcc cp fs0-75rem">
																		<span className='cp checkbox w2rem h2rem pr'>
																			<input
																				checked={company?.['memberPermissions']?.[member['_id']]?.[section['sectionNo']]}
																				onChange={(e) => setPermissions(e, section['sectionNo'], member)}
																				id={`${section['sectionNo']}-${member['_id']}`}
																				className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px"
																				type="checkbox"
																				style={
																					user['_id'] !== company['creatorId']['_id']
																						? { backgroundColor: '#8f8f8f', borderColor: '#8f8f8f' }
																						: {}
																				}
																			/>
																		</span>
																	</label>
																</td>
															)
														})}
													</tr>
												)
											})}
										</>
									)
								})}
						</tbody>
					</table>
				</div>
			</div>


			{/* Audit Log */}
			<TeamPermissionLogs
				getViewableURL={getViewableURL}
				profileClass={"profileClass"}
				modalType={"teamPermissionLogs"}
				logList={allLogs}
			/>
		</>
	);
}
