import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import Button from '../../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { toggleModal } from '../../../reducers/modalReducer';
import { postRequest } from '../../../config';
import { setCompany } from '../../../reducers/companyReducer';

const DisconnectDataroom = ({ modalType, companyId }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const modalState = useSelector((state) => state.modal);
    const selectedCompany = useSelector((state) => state.company.selectedCompany);

    const handleDisconnect = async () => {
        setLoading(true);
        try {
            const response = await postRequest(`/company/disconnect/${companyId}/${modalState?.modalHistory}`, {});

            if (response?.code === 200) {
                const dataroom = modalState?.modalHistory;
                const updatedCompany = { ...selectedCompany };

                const patchMap = {
                    gdrive: {
                        unset: ['gDriveLink', 'folderId'],
                        setFalse: ['foldersPopulated'],
                    },
                    dropbox: {
                        unset: ['dropBoxLink', 'dropBoxFolderMetadata'],
                        setFalse: ['dropBoxFoldersPopulated'],
                    },
                    sharepoint: {
                        unset: ['sharePointLink', 'sharePointFolderId', 'sharePointFolderDriveItem'],
                        setFalse: ['sharePointFoldersPopulated'],
                    },
                };

                const patch = patchMap[dataroom];
                if (patch) {
                    // Remove keys from object
                    patch.unset.forEach((key) => {
                        delete updatedCompany[key];
                    });

                    // Set boolean flags to false
                    patch.setFalse.forEach((key) => {
                        updatedCompany[key] = false;
                    });
                }

                console.log('Updated company:', updatedCompany);
                dispatch(setCompany(updatedCompany));
                dispatch(toggleModal({ open: '', modalHistory: null }));
            } else {
                console.error('Failed to disconnect dataroom:', response?.message);
            }
        } catch (error) {
            console.error('Error disconnecting dataroom:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ModalWrapper
                modalType={modalType}
            >
                <div className='df aic jcc fdc'>
                    <Delete className="c4" style={{ margin: "1rem auto 0px", fontSize: "5rem !important", width: "100px", height: "100px" }} />
                    <p className='pl0rem py2rem fs1rem c15 fw400 tti'>Are you sure to disconnect the {modalState?.modalHistory}?</p>
                </div>
                <div className='df aic jcc w100'>
                    <Button
                        text={"Disconnect"}
                        btnType="warning"
                        onClick={handleDisconnect}
                        disabled={loading}
                    />
                </div>
            </ModalWrapper >
        </>
    )
}

export default DisconnectDataroom