import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import FreeKPI from "./FreeKPI.js";
import Introduction from "./Introduction.js";
import Loader from "../../components/Loader.js";
import { toggleModal } from "../../reducers/modalReducer.js";
import { updateUser } from "../../reducers/userReducer.js";
import { setCompany } from "../../reducers/companyReducer.js";
import { updatePartner } from "../../reducers/partnerReducer.js";
import { getRequest, postRequest, showMessage } from '../../config.js';


export default function PartnerChecklist() {

    const dispatch = useDispatch();
    let user = useSelector(state => state.user);
    let modal = useSelector(state => state.modal);
    let partner = useSelector(state => state.partner);
    let { selectedCompany } = useSelector(state => state.company);

    let [stepTab, setStepTab] = useState(1);
    let [partnerId, setPartnerId] = useState();
    let [moduleName, setModuleName] = useState();

    let [companyObj, setCompanyObj] = useState({});
    let [checklistObj, setChecklistObj] = useState({});
    let [isLoader, setIsLoader] = useState({ show: false, message: 'Loading...' });
    let tabs = { 1: 'Add target company', 2: 'FREE KPI Report', 3: 'Complete checklist', 5: 'Register & store your checklist' };

    useEffect(() => {
        let pathname = location.pathname.split('/');

        if (pathname) {
            partnerId = pathname[pathname.length - 1];
            if (partnerId && partnerId?.includes(';')) partnerId = partnerId.replaceAll(';', '');
            if (partnerId) {
                setIsLoader({ show: true, message: 'Loading Partner...' });
                getPartner(partnerId);
                localStorage.setItem('partnerId', partnerId);
                setPartnerId(partnerId);
            }
        }

        let _id, cName;
        if (location?.hash) {
            _id = (location.hash.split('=')[1]).split(';')[0];
            cName = (location.hash.split('=')[2]).replaceAll('%20', ' ');
            setCompanyObj({ _id, cName });
        }
        if (_id) {
            dispatch(toggleModal({ open: 'partnerChecklistPopup' }));
            setStepTab(2);
            getCompany(_id);
            if (user && (Object.keys(user).length == 0)) getUser();
        };
    }, []);

    useEffect(() => { setChecklistObj({ ...checklistObj, partnerId: partner['_id'] }) }, [partner]);
    useEffect(() => { setChecklistObj({ ...checklistObj, companies: { [companyObj['_id']]: companyObj['cName'] } }) }, [companyObj]);
    useEffect(() => { setChecklistObj({ ...checklistObj, memberPermissions: { [user['_id']]: { ...user, company: companyObj } } }) }, [user]);
    useEffect(() => {
        if (checklistObj['companies'] && checklistObj['memberPermissions'] && checklistObj['partnerId']) {
            createChecklist();
            setChecklistObj({});
        }
    }, [checklistObj]);

    const createChecklist = async () => {
        const res = await postRequest(`/checklist/create/0`, checklistObj);
        if (res?.['code'] == 200) {
            if (res?.['body']?.['module']) setModuleName(res['body']['module']['moduleName'])
        }
    };

    const getPartner = async (id) => {
        setIsLoader({ show: true, message: 'Loading Partner...' });
        let res = await getRequest(`/checklist/partner/${id}`, 'return-response');
        if (res['code'] == 200) {
            dispatch(updatePartner(res['body']));
            setIsLoader({ show: false, message: 'Loading...' });
        }
        else {
            showMessage(res['message']);
            setTimeout(() => window.location.href = '/homepage', 500);
        }
    };

    const getCompany = async (id) => {
        let res = await getRequest(`/company/get/${id}`);
        if (res['code'] == 200) dispatch(setCompany(res['body']));
    };

    const getUser = async () => {
        let response = await getRequest(`/user`);
        if (response && response['code'] == 200) {
            let user = response?.['body']?.['user'];
            dispatch(updateUser(user));
        }
    };

    const generateKPIReport = async () => {
        if (!selectedCompany['keyPerformanceIndicators'] || Object.values(selectedCompany['keyPerformanceIndicators']).length == 0) {
            showMessage('Generating your KPI report, please wait!');
            await getRequest(`/company/generateKPI/${companyObj['_id']}`);
            await getCompany(companyObj['_id']);
            dispatch(toggleModal({ open: '' }));
        }
        else dispatch(toggleModal({ open: '' }));
    };

    const handleChecklistNext = async (moduleName) => {
        if (moduleName) {
            await localStorage.setItem('isPartnerChecklist', true);
            window.location.href = `/company/${selectedCompany['_id']}#${moduleName}`;
        }
        else showMessage('Your checklist creation is in progress, please wait!');
    };

    return (
        <>
            {isLoader['show'] && <Loader message={isLoader['message']} />}
            <div className='checklist-builder-page mt8rem'>
                <div className='checklist-setup df fww w100 bsbb h100'>
                    <div className={`${stepTab == 1 ? 'w100' : 'w80'} left-setup-area bsbb px5rem pr mb2rem`} style={{ border: '1px solid #82828234', borderLeft: 'none', borderBottom: 'none' }}>
                        {stepTab > 1 &&
                            <div className='df aic w100 my1rem fww'>
                                <span onClick={() => setStepTab(stepTab - 1)}>
                                    <svg className='cp mr1rem' width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.2786 0.40705C7.40543 0.279703 7.55715 0.178129 7.7249 0.10825C7.89264 0.0383717 8.07306 0.00158847 8.25563 4.92302e-05C8.43819 -0.0014881 8.61924 0.0322472 8.78821 0.0992886C8.95719 0.16633 9.1107 0.265335 9.2398 0.390524C9.36889 0.515715 9.47098 0.664585 9.54012 0.828446C9.60925 0.992308 9.64404 1.16788 9.64245 1.34492C9.64087 1.52196 9.60294 1.69692 9.53088 1.85959C9.45882 2.02226 9.35407 2.16939 9.22275 2.29239L4.6951 6.68305L20.6251 6.68305C20.9897 6.68305 21.3394 6.82353 21.5973 7.07358C21.8551 7.32362 22 7.66276 22 8.01638C22 8.37001 21.8551 8.70914 21.5973 8.95919C21.3394 9.20924 20.9897 9.34972 20.6251 9.34972L4.6951 9.34972L9.22275 13.7404C9.47321 13.9919 9.61179 14.3287 9.60866 14.6783C9.60553 15.0278 9.46093 15.3623 9.20601 15.6095C8.95108 15.8567 8.60623 15.9969 8.24573 15.9999C7.88523 16.003 7.53792 15.8686 7.2786 15.6257L0.403938 8.95905C0.275896 8.8352 0.174307 8.68806 0.104993 8.52607C0.0356788 8.36409 -3.41367e-07 8.19043 -3.49033e-07 8.01505C-3.56699e-07 7.83967 0.0356787 7.66602 0.104993 7.50403C0.174307 7.34204 0.275896 7.19491 0.403938 7.07105L7.2786 0.40705Z" fill="#151313" />
                                    </svg>
                                </span>
                                <div className='steps c15 fs1-25rem mr1rem'><span>{stepTab}</span> / <span>4</span></div>
                                <h3 className='c15 fs2rem lh2 fw400'>{tabs[stepTab]}</h3>
                                {stepTab == 2 && <div className="df w100 mt0-5rem c3">{partner?.['moduleName'] && partner['moduleName']} <p className='c12 fs1rem lh2 fsi ml0-5rem'>(AI Generated)</p></div>}
                            </div>
                        }
                        {/* Introduction */}
                        {tabs[stepTab] == 'Add target company' && <Introduction />}
                        {/* Bonus FREE KPI Report */}
                        {tabs[stepTab] == 'FREE KPI Report' && <FreeKPI selectedCompany={selectedCompany} moduleName={moduleName} handleChecklistNext={handleChecklistNext} />}

                    </div>
                    {stepTab > 1 &&
                        <div className='w20 right-step-area py2rem pl2rem pr1rem bsbb pr' style={{ border: '1px solid #82828234', borderRight: 'none', borderBottom: 'none', backgroundColor: '#F9F9FF' }}>
                            <div className='top-area h40vh'>
                                {stepTab == 2 && <>
                                    <img className='pr' style={{ right: '130px' }} src={require('../../../images/partner-checklist/kpi-partner-checklist.png')} alt="" />
                                    <div class='w100 df jcc'>
                                        <button onClick={() => handleChecklistNext(moduleName)} data-tooltip={moduleName ? 'Checklist Created! you can click Next to view checklist' : 'Your checklist creation is in progress, please wait!'}
                                            className={`shadow c1 fw400 fs1rem pr br38px w300px py0-5rem mt1rem df aic jcc ${moduleName ? 'bg4 bg3-hover cp' : 'bg16 cna'}`}>
                                            Ok, next
                                            <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                                                </svg>
                                            </i>
                                        </button>
                                    </div>
                                </>}
                            </div>
                            <div className='bottom-checklist pb1rem h50vh df fww ' style={{ width: '85%', alignContent: 'flex-end' }}>
                                {Object.values(tabs).map((item, idx) => {
                                    return <button className={`df aic checklist-btn bo20 bw1px bss br15px bg0 w100 tal mb0-5rem bsbb cp ${stepTab == (idx + 1) && ' active'}`}>
                                        {stepTab > (idx + 1)
                                            ? <img className="number-area bo20 bw2px bss tac br100 w2rem h2rem pr" src={require('../../../images/step-completed-icon.png')} alt="" />
                                            : <i className='number-area bo20 bw2px bss tac br100 w2rem h2rem pr'>{idx + 1}</i>
                                        } {item}
                                    </button>
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* Demo Checklist Popup */}
            <Modal id="partnerChecklistPopup" open={modal['open'] == 'partnerChecklistPopup'} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore">
                <div className="poppins-font-style mah100 bg1 shadow w100 py2rem px4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '750px' }}>
                    <div className='top-logo pa t0 r0 mr1rem mt1rem'><img className='w4rem' src={require('../../../images/favicon.png')} alt="" /></div>
                    <div className='creaing-modal pr mb3rem'>
                        <div className='df jcc'><img className="w10rem" src={require('../../../images/wizard.gif')} alt="" /></div>
                        <div className='text-box w90 df jcc tac fdc mla mra'>
                            <h3 className='fs1-5rem c15 mb1rem'>Performing checklist creation magic!</h3>
                            <div className="my1rem tac">
                                <div className="checklist-progress"><div className={`checklist-progress-bar`}></div></div>
                            </div>
                            <p className='c15 fs1rem mt1rem'>This may take couple of minutes, meanwhile you can check Free Bonus KPI Report.</p>
                            <br />
                            <div class='w100 df jcc'>
                                <button onClick={() => generateKPIReport()} className='df aic jcc pr cp shadow c1 fw400 fs1rem br38px w300px h3rem bg4 bg3-hover'>
                                    Free KPI Report
                                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        </div>
                        <div className='img-box pa' style={{ top: '10px', right: '-345px' }}><img className="" src={require(`../../../images/partner-checklist/create-partner-checklist-popup.png`)} alt="" /></div>
                    </div>
                </div>
            </Modal>
        </>
    )
}