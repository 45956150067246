import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getLinkedInReducer(linkedIn = { isFromLinkedIn: false }) {
	const linkedInSlice = createSlice({
		name: "linkedIn",
		initialState: linkedIn,
		reducers: {
			updateLinkedIn: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return linkedInSlice.reducer;
}

export const updateLinkedIn = createAction("linkedIn/updateLinkedIn");