import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getPartnerReducer(partner = {}) {
	const partnerSlice = createSlice({
		name: "partner",
		initialState: partner,
		reducers: {
			updatePartner: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return partnerSlice.reducer;
}

export const updatePartner = createAction("partner/updatePartner");