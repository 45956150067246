import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import FileUploadRepository from '../../loaders/fileUploadRepository'
import { useSelector } from 'react-redux'

const FileLoaderModal = ({ modalType }) => {
    const { percent = 0, fileName = null, totalFiles = null, filesUploaded = null } = useSelector((state) => state.modal)
    return (
        <>
            <ModalWrapper modalType={modalType} modalCardClasses="maw800px pr" >
                <img
                    style={{
                        position: "absolute",
                        right: "-1rem",
                        top: "-1rem"
                    }}
                    src={require("../../../../images/icon.png")}
                    alt='iconDiligentsia'
                />
                {
                    modalType === "computerToDataRepository" ?
                        <FileUploadRepository
                            percent={percent}
                            fileName={fileName}
                            filesUploaded={filesUploaded}
                            totalFiles={totalFiles}
                            gifFile={<img src={require("../../../../images/loaders/pc-to-data-repository.gif")} alt="fileUpload" />}
                            from="Your Computer"
                            to="Data Repository"
                        /> :
                        modalType === "fileUploadFilingAssistant" ?
                            <FileUploadRepository
                                percent={percent}
                                gifFile={<img src={require("../../../../images/loaders/clarence-to-data-repository.gif")} alt="fileUpload" />}
                                from="Clarence"
                                to="Data Repository"
                            /> :
                            modalType === "computerToClarence" ?
                                <FileUploadRepository
                                    percent={percent}
                                    fileName={fileName}
                                    filesUploaded={filesUploaded}
                                    totalFiles={totalFiles}
                                    gifFile={<img src={require("../../../../images/loaders/pc-to-data-clarence.gif")} alt="pc-to-data-clarence" />}
                                    from="Your Computer"
                                    to="Clarence"
                                />
                                : <></>
                }
            </ModalWrapper>
        </>
    )
}

export default FileLoaderModal