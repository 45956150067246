import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getLoginReducer(loginType = { smsEnabled: '', twoFaEnabled: '', emailEnabled: '', hash: '', isBillingRoute: false }) {
	const loginSlice = createSlice({
		name: "loginType",
		initialState: loginType,
		reducers: {
			setLoginType: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return loginSlice.reducer;
}

export const setLoginType = createAction("loginType/setLoginType");

