// Custom react select 
const customStyles = {
	control: (provided, state) => ({
		...provided,
		cursor: "pointer",
		border: 'none',
		boxShadow: 'none',
		minHeight: 'initial',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0.5rem',
	}),
	input: (provided) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	placeholder: (provided) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: 0,
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	menu: (provided, state) => ({
		...provided,
		width: "96%",
		marginTop: 0,
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#fc7785" : state.isFocused ? "#fde3e6" : provided.backgroundColor,
		color: state.isSelected ? "white" : provided.color,
		':active': {
			...provided[':active'],
			backgroundColor: state.isSelected ? "#fc7785" : "#fde3e6",
		},
	}),
};

const addCompanyCustomStyles = {
	control: (provided, state) => ({
		...provided,
		cursor: "pointer",
		border: '1px solid #DBDFEE',
		boxShadow: 'none',
		minHeight: 'initial',
		borderRadius: '50px',
		padding: '0px 2px',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0.5rem',
	}),
	input: (provided) => ({
		...provided,
		margin: 0,
		padding: 0,
		fontSize: '13px',
	}),
	placeholder: (provided) => ({
		...provided,
		margin: 0,
		padding: 0,
		color: '#151313'
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: 0,
		color: '#4A67FF',
		marginRight: '4px'
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	menu: (provided, state) => ({
		...provided,
		width: "96%",
		marginTop: 0,
		zIndex: 9999,
		top: "80%",
		boxShadow: "0px 8px 14px #07070729",
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#fc7785" : state.isFocused ? "#fde3e6" : provided.backgroundColor,
		color: state.isSelected ? "white" : "#151313B3",
		':active': {
			...provided[':active'],
			backgroundColor: state.isSelected ? "#fc7785" : "#fde3e6",
		},
		fontSize: "13px",
		fontWeight: 600,
		cursor: "pointer",
	}),
};

const hasNotAllTheDataRepositoryPermissions = (userPermissions) => {
	const allTheDataRepoSections = new Set([
		'D001', 'D002', 'D003',
		'D004', 'D005', 'D006',
		'D007', 'D008', 'D009',
		'D010', 'D011', 'D012',
		'D013', 'D014', 'D015',
		'D016', 'D017', 'D018',
		'D019', 'D020'
	]);

	for (const key of allTheDataRepoSections) {
		if (userPermissions[key]) {
			return false;
		}
	}

	return true;
};

const correctSubfolders = (section) => {

	if (!section['subFolders']) {
		return section;
	}

	const keys = Object.keys(section['subFolders']);
	keys.forEach(key => {
		section['subFolders'][key]['name'] = section['subFolders'][key]['name'].replace('/', '-')
		section['subFolders'][key]['name'] = section['subFolders'][key]['name'].replace('\\', '-')
		section['subFolders'][key] = correctSubfolders(section['subFolders'][key]);
	})

	return section;
}

const correctSection = (section) => {
	section['sectionName'] = section['sectionName'].replace('/', '-')
	section['sectionName'] = section['sectionName'].replace('\\', '-')

	return correctSubfolders(section);
};

const convertImgToBase64 = async (url) => {
	const response = await fetch(url);
	const blob = await response.blob();
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};


module.exports = { customStyles, addCompanyCustomStyles, hasNotAllTheDataRepositoryPermissions, correctSection, convertImgToBase64 }
