
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


export default function CompanyAudit({ profileClass, user, auditReportLogs, company }) {

	let [tabPanel, setTabPanel] = useState('Last Week');

	const downloadPDF = async () => {
		const input = document.getElementById('rootElementId');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const imgWidth = 600;
				const pageHeight = 841.88976378;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;
				let position = 0;
				heightLeft -= pageHeight;
				const pdf = new jsPDF({ orientation: 'p', unit: 'pt', format: [600, 841.88976378] });
				pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
					heightLeft -= pageHeight;
				}
				pdf.save(`Company-Audit.pdf`);
			});
	};

	const companyLogTable = (logs) => {
		return (
			<table id="table" className="table-company-log bg1">
				<thead className="ps t0">
					<tr>
						<th>By</th>
						<th>Changes</th>
						<th>Time</th>
						<th>Module</th>
					</tr>
				</thead>
				<tbody>
					{auditReportLogs && auditReportLogs[logs] && auditReportLogs[logs].length > 0
						? <>{
							auditReportLogs[logs].map(log => {
								if (log?.['userId']) {
									return (
										<tr>
											<td>
												<div className="df tac aic jcc w100">
													{/* <span className="mr1rem">{log?.['userId']?.['role'] ? log?.['userId']?.['role'] : 'User'}</span> */}
													<span data-tooltip={log?.['userId']?.['firstName'] ? (log['userId']['firstName'] + " " + log['userId']['lastName']) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 ${profileClass}`}>{log?.['userId']?.['firstName'] ? (log['userId']['firstName'][0] + log['userId']['lastName'][0]) : "?"}</span>
													{/* {log?.['userId']?.['profile']
														? <img className={`w2rem h2rem df jcc aic br100 ${profileClass}`} src={log['userId']['profile']} data-tooltip={log['userId']?.['firstName'] ? (log['userId']['firstName'] + " " + log['userId']['lastName']) : "Register to show your name here"} />
														: <span data-tooltip={log?.['userId']?.['firstName'] ? (log['userId']['firstName'] + " " + log['userId']['lastName']) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 ${profileClass}`}>{log?.['userId']?.['firstName'] ? (log['userId']['firstName'][0] + log['userId']['lastName'][0]) : "?"}</span>
													} */}
												</div>
											</td>
											<td className="">{log?.['change'] && <>
												<p><b>Selected Option</b>: {log?.['change']?.['radioOption'] ? log?.['change']['radioOption'] : '-'}</p>
												<p ><b>Changed Status</b>: {log?.['change']?.['status'] ? log?.['change']['status'] : '-'}</p>
												{log?.['change']?.['files'].length > 0 &&
													<p className="df jcc aic"><b>Files:</b>
														<div className="df fdc">
															{log['change']['files'].map((file, idx) => <Link key={idx} to={file['location']} target="_blank" className="db c3-hover fw600 tdu fs0-75rem ml0-5rem my0-5rem">{file['originalname']}</Link>)}
														</div>
													</p>
												}
											</>}</td>
											<td className="">{moment(log?.['timestamp']).format('lll')}</td>
											<td className="ttc">{(log?.['payload']?.['questionsAndAnswersToSave'][0]?.['type']).replaceAll("-", " ")}</td>
										</tr>
									)
								}
							})
						}</>
						: <tr className="pa mt1rem fs1-25rem fw500" style={{ left: '45%' }}>No Audit Log History!</tr>
					}
				</tbody>
			</table>
		)
	}

	return (
		<div className="container bg1 py2rem mih70vh company-audit-page">
			<div id="rootElementId">
				<div className="report-start">
					<div className="logo-report mb1rem df jcsb aic w100">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
						<button onClick={() => downloadPDF()} className="h100 my0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
							Download PDF
						</button>
					</div>
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
							</div>
						</div>
						<div>
							<ul className="right-list">
								<li>Subscription <span>{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro')
									? 'Portfolio PRO'
									: <>{company['subscription']?.toUpperCase()}</>}
								</span></li>
								<li>Company Reg <span>{company['ukRegNo']}</span></li>
								<li>Issue Date <span>{moment(company['updatedAt']).format("MMM Do YYYY")}</span></li>
								<li>Generated By <span>{company['creatorId']['email']}</span></li>
							</ul>
						</div>
					</div>
					<div className="heading-area mb2rem tac df fdc jcc aic">
						<h2 className="w200 c6 lh2 fs1-25rem" style={{ borderBottom: '3px solid #002d63' }}>Company Audit Log History</h2>
					</div>
				</div>
				<div className='table-area-company-log'>
					<div className="df fdc aic advisor-index">
						<TabContext value={tabPanel}>
							<TabList onChange={(e, value) => setTabPanel(value)}>
								<Tab className="fs0-5rem fw600" label="Last Week" value="Last Week" />
								<Tab className="fs0-5rem fw600" label="Last Month" value="Last Month" />
								<Tab className="fs0-5rem fw600" label="Last Quarter" value="Last Quarter" />
							</TabList>
							<TabPanel className="w100" value="Last Week">{companyLogTable('weekLogs')}</TabPanel>
							<TabPanel className="w100" value="Last Month">{companyLogTable('monthLogs')}</TabPanel>
							<TabPanel className="w100" value="Last Quarter">{companyLogTable('quaterLogs')}</TabPanel>
						</TabContext>
					</div>
				</div>

			</div>
		</div>
	);
};
