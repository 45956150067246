export default function Loader({ message }) {
    return (
        <div className='loader'>
            <div className="loader-area df fdc aic">
                <img class="w30" src={require('../../images/wizard.gif')} />
                {message &&
                    <span className="loading-text">
                        <h3 className="text-gradient">{message}</h3>
                    </span>
                }
            </div>
        </div>
    );
}