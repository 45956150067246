import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getUserReducer(user = {}) {
	const userSlice = createSlice({
		name: "user",
		initialState: user,
		reducers: {
			updateUser: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return userSlice.reducer;
}

export const updateUser = createAction("user/updateUser");