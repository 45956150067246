import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { showMessage } from '../../config.js';
import { toggleModal } from "../../reducers/modalReducer.js";

export default function CreateDemoChecklist({ checklistObj, setChecklistObj, submitCreateChecklist }) {

    const dispatch = useDispatch();


    let [descriptionCount, setDescriptionCount] = useState(0);

    useEffect(() => {
    }, []);

    const handleModuleDescription = (e) => {
        if (e.target.value.length <= 500) {
            setChecklistObj({ ...checklistObj, moduleDescription: e.target.value });
            setDescriptionCount(e.target.value.length);
        }
    };


    return (
        <>
            <div className='description-box df fww'>
                <div className='left-box w60'>
                    <div className='pr mt1rem'>
                        <label className='w100 fw500 c15 db' for='checkListName'>Your Checklist Name <span className={!checklistObj['moduleName'] ? 'c40' : 'c34'}>*</span></label>
                        <input defaultValue={checklistObj['moduleName'] || ''} onChange={(e) => setChecklistObj({ ...checklistObj, moduleName: e.target.value })} id="checkListName" placeholder="e.g. Human Resources Checklist 1.0" className='lpins w100 br10px p1rem mt0-5rem' type="text" />
                    </div>
                    <div className='pr mt2rem'>
                        <label className='w100 fw500 c15 db'>Write Checklist Description <span className={!checklistObj['moduleDescription'] ? 'c40' : 'c34'}>*</span></label>
                        <textarea value={checklistObj['moduleDescription'] || ''} rows='4' style={{ resize: 'none' }} name='moduleDescription' className='lpins w100 br10px p1rem mt0-5rem' onChange={(e) => handleModuleDescription(e)}
                            placeholder='Your checklist description should be concise but comprehensive –​ this will appear as your checklist module description'></textarea>
                        <span className={`pa b0 r0 mb1rem mr1rem ${descriptionCount >= 499 ? 'c4' : 'c16'}`}>{descriptionCount}/500</span>
                    </div>
                </div>
                <div className='right-box w40 bsbb pr1rem pl4rem'>
                    <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                        <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                            <i className='icon mr1rem'><img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" /></i>
                            Example
                        </h5>
                        <h5 className='c15 fs0-75rem' style={{ fontSize: '14px' }}>Description:</h5>
                        <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                            A checklist for the creation of a business plan for the set up of a chain of doggy daycare centers in the UK. The focus of the checklist should include
                            <br />1. Commercial considerations <br />2. Regulatory requirements <br />3. Financing requirements
                        </p>
                        <img className="db w80" style={{ marginTop: '-13px' }} src={require(`../../../images/example-img.png`)} alt="" />
                    </div>
                </div>
            </div>
            <div className='pr df aic w100 jcsb bsbb' style={{ top: '30%' }}>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>2/4 Section Review & Approval</p>
                <button disabled={!checklistObj['moduleName'] || !checklistObj['moduleDescription']} onClick={() => submitCreateChecklist()} className={`c1 shadow fw400 fs1rem pr br38px w200px py0-5rem df aic jcc ${(!checklistObj['moduleName'] || !checklistObj['moduleDescription']) ? ' bg16 cna' : ' bg4 bg3-hover cp'}`}>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div >
        </>
    )
}