import moment from "moment";
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { KeyboardArrowRight, LockOutlined } from "@mui/icons-material";

import { AppMode } from "../../../env";
import 'react-circular-progressbar/dist/styles.css';
import { getRequest, showMessage } from "../../../config";
import { toggleModal } from "../../../reducers/modalReducer";
import GoogleDrive from "../../../components/svgs/GoogleDrive";
import ModalWrapper from "../../../components/common/Modal/ModalWrapper.jsx";
import PassFailCertification from "../../../components/PassFailCertification";

export default function Report({ getCompany, sectionRefs, user, company, moduleCertification, module, tab, setTab, aiEnhanced, setAiEnchaned, twoMinuteTestAIReport, sortSections }) {

	let dispatch = useDispatch();

	let modal = useSelector(state => state.modal);

	let [checklistModule, setChecklistModule] = useState(false);
	let [directorCertification, setDirectorCertification] = useState('None');
	// const sanitiseTab = t => decodeURIComponent(t).replace(/(-sync)?-report$/, "")
	const getHeight = child => child.offsetHeight + parseFloat(getComputedStyle(child).marginTop) + parseFloat(getComputedStyle(child).marginBottom);

	useEffect(() => {
		dispatch(toggleModal({ open: '' }));
		if (tab == 'kpi-report' && (!company['keyPerformanceIndicators'] || Object.values(company['keyPerformanceIndicators']).length == 0)) {
			dispatch(toggleModal({ open: 'generatingKPI' }));
			generateKPI();
		}
	}, []);

	const generateKPI = async () => {
		showMessage('Generating your KPI report, please wait!');
		let res = getRequest(`/company/generateKPI/${company['_id']}`);
		if (res['code'] == '200') await getCompany(company['_id']);
		dispatch(toggleModal({ open: '' }));
	}

	useEffect(() => {
		if (!module) setTab(tab);
		if (tab && module && tab.includes(`directors-questionnaire_${module?.['director']?.['_id']}-report`)) {
			if (module?.stats?.completionPercent >= 90 && module?.stats?.riskPercent <= 50) setDirectorCertification('Gold')
			else if (module?.stats?.completionPercent >= 75 && module?.stats?.riskPercent <= 50) setDirectorCertification('Silver')
			else setDirectorCertification('None')
		}
	}, []);

	useEffect(() => {
		if (user['role'] == 'Guest' && company?.['keyPerformanceIndicators']?.length == 0) getCompany();
	}, []);

	useEffect(() => {
		if (module?.['type']) {
			if (!(['kpi', `two-minute-test-${company['testType']}`, 'repository', 'software-development', 'esg', 'warranty-disclosure', `business-audit-${company['testType']}`, `directors-questionnaire_${module?.['director']?.['_id']}`].includes(module['type']))) setChecklistModule(true);
		}
	}, [module]);

	useEffect(() => {
		document.getElementById("pdfContent").classList.add("a4-pdf", "p3rem");
		let wrapper = document.getElementById("pdf");
		wrapper.innerHTML = "";
		let children = Array.from(document.getElementById("pdfContent").children);
		let i = 0;
		let _pageNumber = 1
		let page = document.createElement("div");
		page.classList.add("a4-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "p1-5rem", "shadow", "new-report");
		wrapper.appendChild(page);  // Append the first page directly
		let usedPageHeight = 96;  // Initial height used on the page
		let pageHeight = getHeight(page);
		let footerHeight = 8;

		// Create watermark
		let watermark = document.createElement("img");
		watermark.classList.add("op20", "pa");
		watermark.style.transform = "translate(-50%, -50%) rotate(30deg)";
		watermark.style.top = "50%";
		watermark.style.left = "50%";
		watermark.style.width = "297mm";
		page.appendChild(watermark);

		let section = document.querySelector('#pdfContent .section-break');
		while (children.length && i < 250) {
			// Process children for table and other content
			for (let child of children) {
				i++;
				// Create footerLogo
				let footer = document.createElement("div");
				footer.classList.add("tac", "fw600", "df", "jcsb", "aife", "pa", "c36", "b10", "l0", "pdfFooterCustom");
				let footerLogo = document.createElement("img");
				footerLogo.src = "https://repository-diligentsia.s3.ap-northeast-1.amazonaws.com/development/miniicon-1735284051094.png";
				footerLogo.alt = "miniicon";
				footerLogo.classList.add("h40px", "w40px", "tal");

				// Create trademark
				let trademark = document.createElement("p");
				trademark.innerText = `© Diligentsia Limited ${new Date().getFullYear()}`;
				trademark.classList.add("tac", "c37", "fs12px", "fw400");

				// Create pageNumber
				let pageNumber = document.createElement("h3");
				pageNumber.innerText = "Page " + _pageNumber
				pageNumber.classList.add("fw600", "c36", "fs14px");

				// Append footerLogo and trademark to footer
				footer.appendChild(footerLogo);
				footer.appendChild(trademark);
				footer.appendChild(pageNumber);

				let childHeight = getHeight(child);
				// if (usedPageHeight + childHeight + footerHeight > pageHeight) {
				if ((usedPageHeight + childHeight + footerHeight > pageHeight) || (child?.className == section?.className)) {
					page.classList.add("df", "fdc");
					if (i > 1) {
						_pageNumber++;
						page = createNewPage(wrapper);
						page.appendChild(footer);
					}
					usedPageHeight = 96;
				}
				page.appendChild(child.cloneNode(true));
				usedPageHeight += childHeight;
			}

			// Remove processed children
			children.splice(0, children.length);
		}

		// Clean up after processing
		wrapper.classList.remove("op0", "pen");
		document.getElementById("pdfContent").classList.remove("a4", "p3rem");

		// Set up event listeners for inputs and textareas
		setupEventListeners(wrapper);
	});

	function createNewPage(wrapper) {
		let newPage = document.createElement("div");
		newPage.classList.add("a4-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "p1-5rem", "shadow", "new-report");

		let header = document.createElement("img");
		header.classList.add("db", "ma", "h1rem");
		titleHeading(newPage);

		// Add additional headers based on the module type
		addHeadersBasedOnModule(newPage);


		wrapper.appendChild(newPage);
		return newPage;
	};

	function addHeadersBasedOnModule(page) {
		if (module?.['type'].includes("repository")) repositoryHeaders(page);
		if (module?.['type'].includes("warranty-disclosure")) warrantyDisclosureHeaders(page);
		if (["software-development", "esg"].includes(module?.['type']) || checklistModule) riskRecommendationHeaders(page);
		if (module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`)) directorsQuestionnaireHeaders(page);
	};

	function setupEventListeners(wrapper) {
		let pdfInputs = wrapper.querySelectorAll("#pdf input");
		pdfInputs.forEach(input => input.addEventListener('click', (e) => saveAnswerInput(e)));
		let pdfTextareas = wrapper.querySelectorAll("#pdf textarea");
		pdfTextareas.forEach(textarea => textarea.addEventListener('change', (e) => saveAnswerInput(e)));
	};

	const titleHeading = (page) => {
		const title = document.createElement("span");
		let div = null;
		let quesAnsHead = null
		title.classList.add("db", "fw700", "fs1-25rem", "c15", 'ttu');
		if (tab.match(/two-minute-test-.*-report/)) {
			title.classList.add("tal", "w100");
			title.innerText = `2 Minute ${module?.heading} Report`

			div = document.createElement("div");
			div.classList.add("db", "fb", "fsi", "fs1rem", "c15", "mb0-5rem");
			div.innerText = `${company['cName']}`

			quesAnsHead = document.createElement("div");
			quesAnsHead.classList.add("w100", "br10px", "p0-5rem", "fs1rem", "bg52", "df", "aic", "c1");

			let leftBar = document.createElement("div");
			leftBar.innerText = "Question"
			leftBar.classList.add("w80", "pl1rem")

			let rightBar = document.createElement("div");
			rightBar.innerText = "Answer"
			rightBar.classList.add("w20")

			quesAnsHead.appendChild(leftBar);
			quesAnsHead.appendChild(rightBar);

			page.appendChild(title);
			page.appendChild(div);
			page.appendChild(quesAnsHead);
			return page
		} else if (checklistModule) {
			title.classList.add("tac", "mb1-75rem");
			title.innerText = module['type'] + ' Report' + ' - ' + company['cName']
		} else {
			title.classList.add("tal", "w100");
			title.innerText = `${module?.heading} Report`
			div = document.createElement("div");

			div.classList.add("db", "fb", "fsi", "fs1rem", "c15", "mb1rem");
			div.innerText = `${company['cName']}`
			let warpElement = document.createElement('div')
			let warpElement2 = document.createElement('div')

			let logoTag = document.createElement('img')
			logoTag.classList.add("sad");
			logoTag.src = company['logo']
			logoTag.alt = "companyLogo"
			logoTag.style.maxWidth = "150px"
			logoTag.style.maxHeight = "60px"

			warpElement2.appendChild(title);
			warpElement2.appendChild(div);
			warpElement.classList.add("df", "aic", "jcsb");

			warpElement.appendChild(warpElement2);
			if (company['logo']) {
				warpElement.appendChild(logoTag);
			}
			page.appendChild(warpElement);
			return page;
		}

		page.appendChild(title);
		return page;
	};

	const repositoryHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg52", "c1", "tac", "br10px");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const riskRecommendationHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Risk</h3><h3>Recommendation</h3>";
		page.appendChild(headings);
	};

	const warrantyDisclosureHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Disclosure</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const directorsQuestionnaireHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Text</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const sortQuestions = (a, b) => {
		let valueA = a.qNo.split('.')[a.qNo.split('.').length - 1]
		let valueB = b.qNo.split('.')[a.qNo.split('.').length - 1]

		return valueA - valueB;
	};

	const sortLogs = (a, b) => new Date(b.timestamp) - new Date(a.timestamp);

	const getAuditContent = () => {
		const sectionAnswers = answers.filter(a => !Object.values(module?.sections).filter(s => s.isHidden).find(s => a.qNo.includes(s.sectionNo)));
		const logs = sectionAnswers.filter(a => a.logs.length).reduce((arr, a) => [...arr, ...a.logs], []).sort(sortLogs);
		return logs.map((log, idx) =>
			<div key={idx} className="bg1 p1rem br5px df my0-5rem">
				{log.userId.profilePicture
					&& <img className="ass ml0-5rem br100 w2rem h2rem" src={log.userId.profilePicture} />
					|| <span className="ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem bg3 c1">{log.userId.firstName[0]}{log.userId.lastName[0]}</span>
				}
				<div className="">
					<span><b>{log.message}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>
					{log.files.map((file, idx) => <Link key={idx} to={file.location} target="_blank" className="db c3-hover fw600">{file.originalname}</Link>)}
					{(log.payload.answers || [log.payload]).map((answer, idx) => {
						const question = answer.questionId ? questions.find(q => q._id == answer.questionId) : "";
						return <Fragment>
							{question && <p className="mt0-5rem"><b>{question.qNo}</b> - {question.qDescription}</p>}
							{answer.file && <p className="mt0-5rem"><b>Filename</b>: {answer.file.originalname}</p>}
							{answer.status && <p className="mt0-5rem"><b>Changed Status</b>: {answer.status}</p>}
							{answer.radioOption && <p className="mt0-5rem"><b>Selected Option</b>: {answer.radioOption}</p>}
							{answer.comment && <p className="mt0-5rem"><b>Entered Comment</b>: {answer.comment}</p>}
						</Fragment>
					})}
				</div>
			</div>
		);
	};

	const getSyncContent = () => {
		const records = module?.sections.flatMap(section => section?.questions?.flatMap(question => (question?.answer?.logs || []))).sort(sortLogs);
		return <>
			<h2 className="df jcc aic mb1-3rem">
				<GoogleDrive className="w2rem mr0-5rem" />
				Google Drive Synchronization Report
			</h2>
			<div className="dg gtcr3 bg6 c1">
				<h3 className="fs1-5rem p1rem">Date Synced</h3>
				<h3 className="fs1-5rem p1rem">Synced By </h3>
				<h3 className="fs1-5rem p1rem">Files Synced</h3>
			</div>
			{records.map((record, idx) => (
				<div key={`record-${idx}`} className="dg tableRow gtcr3">
					<div className="df fdc jcc aic p1rem">{moment(record['timestamp']).format('lll')}</div>
					<div className="df fdc jcc aic p1rem"><span>{`${record['userId']['firstName']} ${record['userId']['lastName']}`}</span><br />{record['userId']['email']}</div>
					<div className="df fdc jcc aic p1rem" style={{ textAlign: 'left' }}>
						{record['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}
					</div>
				</div>
			))}
		</>
	};

	const getOverviewContent = () => {
		let hideRisk = false;
		if (module?.['riskScoring'] == false || tab == 'repository-report') hideRisk = true;
		else hideRisk = false;

		let array = [];
		if (['warranty-disclosure', `directors-questionnaire_${module?.['director']?.['_id']}`, 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		sectionRefs.current.length = array.length;
		// array = array.filter((section) => section.isSection);

		return <div>
			<div className="df jcsb aic">
				{checklistModule
					? <h3 className="w100 tal c15 lh2 fs1-25rem ttc">{module?.['type'] + ' Report'}</h3>
					: (tab.match(/two-minute-test-.*-report/)) ?
						<h3 className="w100 tal c15 lh2 fs1-25rem ttc">{'2 Minute ' + module?.heading + ' Report'}</h3>
						: <h3 className="w100 tal c15 lh2 fs1-25rem ttc">{module?.heading + ' Report'}</h3>
				}
				{company['logo'] ? <img src={company['logo']} style={{ maxHeight: "70px", maxWidth: "150px" }} alt="companyLogo" /> : null}
			</div>
			<div className="mt0-5rem dg gg0-5rem minh100vh-report">
				<h4 className="fs1rem fsi fw400 lh1 ttu usn c15">{company['cName']}</h4>
				<div className="report-start">
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc aifs ">
							{[["Company Reg:", company['ukRegNo']], ["Subscription:", (company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro' ? 'Portfolio PRO' : <>{company['subscription']?.toUpperCase()}</>], ["Objective :", company['testType']]].map((arr, idx) =>
								<div key={`reportLeft-Head-${idx}`} className="df aic jcfs">
									<p className="ass fs12px mt0-25rem mb0-25rem c15">{arr[0]}</p>
									<h2 className="fs12px fw700 lh1 ttu usn c15 ml0-5rem">{arr[1]}</h2>
								</div>
							)}
						</div>
						<div className="df fdc aife">
							{[["Generated By", user.email], ["Report Date", moment().format("MMM Do YYYY")]].map((arr, idx) =>
								<div key={idx} className="df aic jcfs">
									<p className="ass fs12px mt0-25rem mb0-25rem c15">{arr[0]}: </p>
									<h2 className="fs12px fw700 lh1 ttu usn c15 ml0-5rem">{arr[1]}</h2>
								</div>
							)}
						</div>
					</div>
					<div className={`progress-area-report bo31 bss br20px bw1px mb1rem px1rem py0-5rem`}>
						<div className='progress-area' style={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className='progress-box p1rem'>
								<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#FBFBFB', borderRadius: '50%' }}>
									<div className={`${module?.stats?.completionPercent > 67 ? 'bg-green'
										: module?.stats?.completionPercent > 33 ? 'bg-orange'
											: 'bg-red'} c2 df jcc aic`} style={{ width: "68px", height: "68px", borderRadius: '50%' }}>
										{(module?.stats?.completionPercent || 0) + "%"}
									</div>
								</div>
								<span className="text-center-area mt1rem c37 mb0rem fs1rem tac">Module <br></br>Completeness</span>
							</div>
							{tab.match(/two-minute-test-.*-report/)
								? <div className="df aic jcc fdc ">
									<div className="certification-area br10px">
										{company['moduleCertification'] && <PassFailCertification className={'op70'} testType={company['testType']} level={moduleCertification} expiryTimestamp={company['createdAt']} />}
									</div>
									<h3 className="c15">Your Overall Result</h3>
								</div>
								: <div className="df jcc mb1rem">
									{((tab && module && tab.includes(`directors-questionnaire_${module?.['director']?.['_id']}`)) && directorCertification)
										? <>
											{directorCertification == 'None' && <img className='w50' src={require(`../../../../images/insufficient-label.png`)} alt="Label" />}
											{directorCertification == 'Silver' && <img className='w30' src={require(`../../../../images/silver-cup.png`)} alt="Trophy" />}
											{directorCertification == 'Gold' && <img className='w30' src={require(`../../../../images/gold-cup.png`)} alt="Trophy" />}
										</>
										: <>
											{moduleCertification == 'Bronze'
												? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/bronze-cup.png`)} alt="Trophy" />
												: moduleCertification == 'Silver'
													? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/silver-cup.png`)} alt="Trophy"
													/>
													: moduleCertification == 'Gold'
														? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/gold-cup.png`)} alt="Trophy" />
														: moduleCertification == 'None'
															? <img className={`w50 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/insufficient-label.png`)} alt="Label" />
															: null
											}</>}
								</div>
							}
							{hideRisk
								? <div className='progress-box-hidden'></div>
								: <div className='progress-box p1rem'>
									<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#FBFBFB', borderRadius: '50%' }}>
										<div className={`${module?.stats?.riskPercent > 67 ? 'bg-red'
											: module?.stats?.riskPercent > 33 ? 'bg-orange'
												: 'bg-green'} c2 df jcc aic`} style={{ width: "66px", height: "66px", borderRadius: '50%' }}>
											{(module?.stats?.riskPercent || 0) + "%"}
										</div>
									</div>
									<span className="text-center-area mt1rem c37 mb0rem fs1rem tac">Module <br></br>Risk</span>
								</div>
							}
						</div>
					</div>
					<div className="h100vh osc">
						<div className="df aic mb0-5rem">
							<div className="c37 headingReport fs14px tac">Section</div>
							<div className="c37 fs14px pl2rem">Name</div>
						</div>
						{array.length > 0 && array.sort(sortSections).map((section, idx) => {
							let completeness = section.stats.completionPercent;
							if (module['type'] == 'repository' && company['subscription'] == 'Lite') {
								if (section.questions) completeness = section.questions.filter(q => q.qNo == section.sectionNo)[0]?.['answer']?.['status'] == 'Completed' ? 100 : 0;
							}
							else completeness = section.stats.completionPercent;
							let riskColour;
							if (module?.type === "repository" || section.stats.riskPercent < 38) riskColour = 7;
							else if (section.stats.riskPercent > 62) riskColour = 9;
							else riskColour = 8;

							let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";
							let colorChange = false;
							if (module?.['type'] == "warranty-disclosure") {
								section?.questions.map(question => {
									if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
										colorChange = true;
										return;
									}
								})
							}
							return <button key={`${idx}-report`} data-scroll-to={section.sectionNo} className="df c15 td250 px1rem py0-5rem cp new-report-btn bg54" style={{
								opacity: `${section.isDisabled ? 0.5 : 1}`
							}}>
								<span className="pen usn ass mr0-5rem tac left-heading df aic jcc c15">
									<KeyboardArrowRight className="pa l10 c3" />
									{section.sectionNo}
								</span>
								<span
									style={{ background: `${section.isDisabled ? "#fff" : `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)`}`, opacity: `${section.isDisabled ? 0.5 : 1}` }}
									className="pen uss ass fw600 tal right-detail fs14px c15 df aic jcsb">{section.sectionName}
									{section.isDisabled && <LockOutlined className="c15 fw400 fs1rem" />}
								</span>
							</button>

						})}
					</div>
				</div>
			</div>
		</div>
	};

	const saveAnswerInput = async (event) => {
		// let answerId = event.target.id.split('_')[1];
		// const res = await postRequest(`/question/addNote/${answerId}`, { done: true });
		// showMessage(res?.['message']);
	};

	const sortQuestionsByQNo = (questions) => {
		if (questions && questions.length > 0) {
			return questions && questions.slice().sort((a, b) => {
				const aParts = a['qNo'].match(/\d+/g).map(Number);
				const bParts = b['qNo'].match(/\d+/g).map(Number);
				for (let i = 0; i < Math.max(aParts.length, bParts.length); i++)
					if ((aParts[i] || 0) !== (bParts[i] || 0)) return (aParts[i] || 0) - (bParts[i] || 0);
				return 0;
			});
		}
	};

	const getAuditReportContent = () => {
		let array = [];
		if (['warranty-disclosure', `directors-questionnaire_${module?.['director']?.['_id']}`, 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		sectionRefs.current.length = array.length;
		// array = array.filter((section) => section.isSection);
		array = array.slice().sort(sortSections);

		return <>
			{array.length > 0 && array.map((section, index) => {
				let questions;
				let completionPercent, riskPercent;
				const qNo = section.sectionNo || section.no;
				if (module['type'] == 'repository' && company['subscription'] == 'Lite') questions = section.questions && section.questions.filter(q => q.qNo == qNo) || [];
				else questions = section?.questions;

				// questions = questions.sort(sortQuestions);
				questions = sortQuestionsByQNo(questions);

				if (section?.stats?.completionPercent) completionPercent = section.stats.completionPercent;
				if (section?.stats?.riskPercent) riskPercent = section.stats.riskPercent;

				if (module['type'] == 'repository' && company['subscription'] == 'Lite') {
					if (section.questions) completionPercent = section.questions.filter(q => q.qNo == section.sectionNo)[0]?.['answer']?.['status'] == 'Completed' ? 100 : 0;
				}

				return (
					<Fragment>
						{/* move the section to next page  */}
						<div className="section-break"></div>

						<div className="mt1rem">
							<h2 className="df jcc aic tac mb1rem fs1rem">
								{section['sectionNo']} - {section['sectionName']} Report &nbsp;-&nbsp;
								{completionPercent && completionPercent > 0
									? <>
										<span className={completionPercent < 34 ? "c9" : completionPercent < 67 ? 'c8' : 'c7'}>Completed {completionPercent || 0}%</span>
										{(riskPercent > 0 && module['type'] != "repository") &&
											<> &nbsp;-&nbsp; <span className={riskPercent < 38 ? "c7" : riskPercent < 62 ? 'c8' : 'c9'}>{riskPercent || 0}% Risk</span> </>
										}</>
									: <>
										<span class='c12'>Not Started</span>
										{module['type'] != "repository" && <> &nbsp;-&nbsp; <span class='c12'>No Risk Score</span></>}
									</>
								}
							</h2>
						</div>
						{questions && questions.length > 0 && questions.filter(q => q.answer)?.map((question, idx) => {
							if (!aiEnhanced && Object.values(question?.['answer']?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
							let isRisky = question?.['answer']?.['radioOption'] == "No";
							return (
								<>
									<div id={qNo} key={`pdf-report-${idx}-${index}`} className={`dg tableRow fs0-75rem ${["repository", `directors-questionnaire_${module?.['director']?.['_id']}`].includes(module?.['type']) ? 'gtcr-audit' : 'gtcr-audit-two'}`}>
										<div className={`df fdc aic jcfs pt0-25rem br5px`}>
											<div className="c15">
												<span className="fw600">{question['qNo']}:</span>
												<p className="c2 db fw500">{question?.['qDescription']}</p>
											</div>
										</div>
										{module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`)
											? <div className="df fdc aic tac jcfs pt1rem br5px">{question?.questionType.includes("YN") ? (question.answer['radioOption'] || question.answer['status']) : "-"}</div>
											: <div className={`df fdc aic tac jcfs pt1rem br5px`}>
												<p className={`${["Disclosure", "No Disclosure"].includes(question?.answer?.['radioOption']) ? ' px0-5rem ' : ' px1rem '} br10px c1 ${['Yes', 'No Disclosure'].includes(question?.answer?.['radioOption']) ? ' bg34' : (question?.answer?.['radioOption'])?.includes('No') ? ' bg4' : (question?.answer?.['radioOption'])?.includes('Disclosure') ? ' bg39' : ' bg16'}`}
													style={{ paddingTop: "4px", paddingBottom: "4px", }}>
													{question.answer['radioOption'] || question.answer['status'] || 'N/A'}
												</p>
											</div>
										}
										{module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`) && <>
											<div className="df fdc aic tac jcfs pt1rem">
												{question?.questionType.includes("Date")
													? <>{question?.answer && <p>{moment(question?.answer?.date).format('ll')}</p>}</>
													: <>{question?.['answer'] && <p>{question['answer'] ? (question['answer']['comment'] || question['answer']['dropdown']) : '-'}</p>}</>
												}
											</div>
											<div className="df fdc aic tac jcfs pt1rem">
												{question.answer.files.length > 0
													? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
													: <p>-</p>
												}
											</div>
										</>
										}
										{module?.['type'] == "repository" && <>
											<div className="db pt1rem br5px" style={{ marginLeft: "30px" }}>
												{question?.answer?.files?.length > 0
													? <>
														{question?.['answer']?.['files'] && question['answer']['files'].slice(0, 20).map((file, idxI) => {
															let fileColor = file.originator == 'filing-assistant' ? 'filing-assistant-file' : file.originator == 'sharepoint' ? 'sharepoint-file' : file.originator == 'dropbox' ? 'dropbox-file' : file.originator == 'gdrive' ? 'gdrive-file' : file.bucket == `repository-diligentsia${AppMode === "production" ? "-prod" : ""}` ? "upload-file" : 'prepopulated-file';

															return (<a className={`${fileColor} fileManageFileName pr mb0-5rem pl2rem`} key={`laal-${idx}-${idxI}`} href={file['location']} target="'_blank">
																{file['originalname']}</a>)
														})}

														{question['answer']['files'].length > 20 ? <a
															key={`question-${idx}-sd`}
															className="cp"
															target="_blank"
															href={`/company/${company['_id']}#${location.href.split('#')[1]}`}
															style={{ marginTop: "12px", fontWeight: 700 }}>
															for more information click here {location.href.split('#')[1]}</a> : null}
													</>
													: <p className="op30 c15">No Files Uploaded</p>
												}
												<div className="df fdc aic tac jcfs pt1rem br5px c37 pl2rem">
													Notes - {question?.['answer'] && <p>{question['answer']['comment']}</p>}
												</div>
											</div>
										</>
										}
										{module?.['type'].includes(`business-audit-${company['testType']}`) && <>
											{question?.['answer']?.['status'] == 'Completed' &&
												<div className="df fdc tal jcfs pt0-5rem">
													<div className="df pb0-5rem">
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifriskDescription'] ? question?.['answer']['aiResponse']['ifriskDescription'] : question['riskDescription'] ? question['riskDescription'] : '-'}
															</> : "Well done"
															}
														</div>
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] ? question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] : question['recommendDescription'] ? question['recommendDescription'] : '-'}
															</> : "No recommendation at this time"
															}
														</div>
													</div>
													{/* Report-Note */}
													{/* <div style={{ background: '#e1e1f155' }}>
													{question['answer'] &&
														<div className="df fdc jcc aic">
															<div><textarea className=" h100 br5px p0-5rem fs0-75rem" id={'note_' + question['answer']['_id']} name='note' onChange={saveAnswerInput} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
															<div className=" df jcsa">
																{(question['answer']['done'] || question['answer']['note']) &&
																	<p>Last Edited: {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																}
																<span className="checkobox-report asc pr ml1rem">
																	<input style={{ width: '25px', height: '25px' }} id={'done_' + question['answer']['_id']} name='status' onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																</span>
															</div>
														</div>
													}
												</div> */}
												</div>
											}
										</>}
										{checklistModule && question?.['answer']?.['radioOption'] != 'N/A' && <>
											<div className='df fdc tal jcs pt0-5rem '>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">{question?.['riskDescription'] || question?.['ifNo']}</div>
													<div className="w50 tac px0-5rem">
														{question?.['recommendDescription'] || question?.['toptip'] || (question.answer.radioOption == "Yes" && question?.['ifYes'])}
													</div>
												</div>
												<p className="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files:
													{question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 ? question['answer']['files'].map((file, idx) => <a className="ml0-5rem tdu" key={idx} href={file['location']} target="'_blank">
														{file['originalname']}</a>)
														: <span className="ml0-5rem fs0-75rem fw500">No files uploaded</span>}
												</p>
												{question?.['answer']?.['comment'] &&
													<p className="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Note: {question?.['answer']?.['comment']}</p>
												}
											</div>
										</>
										}
										{["warranty-disclosure", "software-development", "esg"].includes(module?.['type']) &&
											<div className='df fdc tal jcfs pt0-5rem'>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure' && question['answer']['radioOption'] == 'Disclosure'
															? question?.['answer']?.['comment'] && question?.['answer']?.['comment']
															: module?.['type'] == 'warranty-disclosure' ? '-' : question?.['answer']?.['aiResponse']?.['ifriskDescription'] ? question?.['answer']['aiResponse']['ifriskDescription'] : question['riskDescription'] ? question['riskDescription'] : '-'
														}
													</div>
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure'
															? question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)
															: question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] ? question?.['answer']['aiResponse']['ifrecommendDescription'] : question['recommendDescription'] ? question['recommendDescription'] : '-'
														}
													</div>
												</div>
												{(["software-development", "esg"].includes(module?.['type'])) && question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 &&
													<p className="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files: {question['answer']['files'].map(file => <a className="ml0-5rem tdu" href={file['location']} target="'_blank">{file['originalname']}</a>)}</p>}
												{/* Report-Note */}
												{["software-development", "esg"].includes(module?.['type']) && question?.['answer']?.['comment'] &&
													<p className="w90 fs0-75rem p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Note: {question['answer']['comment']}</p>}
												{/* <div className="br5px" style={{ background: '#e1e1f155' }}>
													{question['answer'] &&
														<div className="df fdc jcc aic">
															<div><textarea id={'note_' + question['answer']['_id']} name="note" className=" h100 br5px p0-5rem fs0-75rem" type="checkbox" onChange={saveAnswerInput} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
															<div className=" df jcsa">
																<p>Last Edited:- {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																<span className="checkobox-report asc pr ml1rem">
																	<input id={'done_' + question['answer']['_id']} name="status" style={{ width: '25px', height: '25px' }} onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																</span>
															</div>
														</div>
													}
												</div> */}
											</div>
										}
									</div >
								</>
							)
						})}
					</Fragment >
				)
			}
			)}
		</>
	};

	const getTwoMinuteTestContent = () => {

		const writeSections = sections => Object.values(sections).filter(s => !s.isHidden).map((section, index) => {
			const questionsAndAnswers = section?.questions?.slice().sort(sortQuestions).filter(q => q?.answer?.radioOption);
			if (questionsAndAnswers && questionsAndAnswers.length) sectionRefs.current.length = questionsAndAnswers.length;

			return <>

				{questionsAndAnswers && questionsAndAnswers.length > 0 && <>
					<h3
						id={section.sectionNo}
						ref={(el) => {
							if (section['sectionNo']) {
								sectionRefs.current[index] = el
							}
						}}
						className="usn pen fs1rem fw400 mt1rem tal c15 w100"
					>
						<span className={`fw600`}>Section {index + 1}</span> - {section.sectionName}
					</h3>
					{questionsAndAnswers.map((question, idx) => {
						const { answer } = question;
						if (!aiEnhanced && Object.values(answer?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
						return <Fragment key={`section-random-${idx}`}>
							<div className="question-box c1 mt0-5rem">
								<h4 className="c15 fs1rem">{question['qNo']}</h4>
								<div className="df aifs lg-fdc">
									<p className="c15 fs14px w80">{question['qDescription']}</p>
									<div className="w20">
										<span className={`${(question['answer']['radioOption'] == 'Yes' || question['answer']['radioOption'] < 3) ? 'bg-green' : 'bg-red'} c1 br50px px1rem ml1rem fs14px py0-5rem`}>
											{question['answer']['radioOption']}
										</span>
									</div>
								</div>
								<div className="recomended-box mt1rem">
									{(() => {
										const recommendationKey = ["ifYes", "ifNo", "", "if1", "if2", "if3", "if4", "if5"].find(key => key.includes("if" + answer.radioOption));
										return recommendationKey && question[recommendationKey] && <div className="bg53 br10px p1rem mt1rem">
											<p className="c15 fsi df aic fs14px mb0-5rem">
												<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_5984_54722)">
														<path d="M16.2103 16.9044C15.9637 16.2436 15.2247 15.9077 14.5645 16.1561L11.8761 17.1671C11.1253 17.4497 10.3345 17.5799 9.54688 17.562L10.2036 20.0462C11.202 19.9596 12.1924 19.7419 13.148 19.3895L15.4626 18.5354C16.1217 18.2924 16.4563 17.5624 16.2103 16.9044Z" fill="url(#paint0_linear_5984_54722)" />
														<path d="M17.8688 16.6819C17.5837 16.0519 16.8394 15.7745 16.2112 16.0639L13.6529 17.2414C12.6572 17.6999 11.5662 17.8758 10.4942 17.7678L9.5 20.2757C11.3823 20.4392 13.2926 20.1219 15.0438 19.3293L17.2476 18.3315C17.8751 18.0475 18.1531 17.3091 17.8688 16.6819Z" fill="url(#paint1_linear_5984_54722)" />
														<path d="M19.0177 16.731C18.6862 16.1084 17.9094 15.8728 17.2877 16.206L14.7559 17.5622C13.7705 18.0903 12.6696 18.337 11.5697 18.2939L10.7188 20.9049C12.6483 20.9539 14.5753 20.5125 16.3093 19.5978L18.4913 18.4463C19.1127 18.1186 19.3482 17.3508 19.0177 16.731Z" fill="url(#paint2_linear_5984_54722)" />
														<path d="M10.5037 0.5C10.1392 0.5 9.84375 0.795482 9.84375 1.15998V2.53235C9.84375 2.89684 10.1392 3.19232 10.5037 3.19232C10.8682 3.19232 11.1637 2.89684 11.1637 2.53235V1.15998C11.1637 0.795482 10.8682 0.5 10.5037 0.5Z" fill="url(#paint3_linear_5984_54722)" />
														<path d="M20.087 10.0898H18.7147C18.3502 10.0898 18.0547 10.3853 18.0547 10.7498C18.0547 11.1143 18.3502 11.4098 18.7147 11.4098H20.087C20.4515 11.4098 20.747 11.1143 20.747 10.7498C20.747 10.3853 20.4515 10.0898 20.087 10.0898Z" fill="url(#paint4_linear_5984_54722)" />
														<path d="M2.28235 10.0898H0.909976C0.545482 10.0898 0.25 10.3853 0.25 10.7498C0.25 11.1143 0.545482 11.4098 0.909976 11.4098H2.28235C2.64684 11.4098 2.94232 11.1143 2.94232 10.7498C2.94228 10.3853 2.6468 10.0898 2.28235 10.0898Z" fill="url(#paint5_linear_5984_54722)" />
														<path d="M4.18133 3.50189C3.9236 3.24416 3.5057 3.24416 3.24798 3.50189C2.99026 3.75961 2.99026 4.17747 3.24798 4.43519L4.21839 5.4056C4.47611 5.66332 4.89397 5.66332 5.15173 5.4056C5.40946 5.14787 5.40946 4.73002 5.15173 4.47225L4.18133 3.50189Z" fill="url(#paint6_linear_5984_54722)" />
														<path d="M17.7454 3.50189C17.4877 3.24416 17.0699 3.24416 16.8121 3.50189L15.8417 4.47229C15.584 4.73002 15.584 5.14787 15.8417 5.40564C16.0995 5.66336 16.5173 5.66336 16.7751 5.40564L17.7455 4.43523C18.0032 4.17751 18.0032 3.75961 17.7454 3.50189Z" fill="url(#paint7_linear_5984_54722)" />
														<path d="M5.3953 5.6453C2.57657 8.46404 2.57657 13.0341 5.3953 15.8529C8.21404 18.6716 12.7841 18.6716 15.6029 15.8529C18.4216 13.0341 18.4216 8.46404 15.6029 5.6453C12.7841 2.82657 8.21404 2.82657 5.3953 5.6453Z" fill="url(#paint8_linear_5984_54722)" />
														<path d="M10.5021 5.31641C7.5022 5.31641 5.07031 7.74829 5.07031 10.7482C5.07031 13.7481 7.5022 16.18 10.5021 16.18C13.502 16.18 15.9339 13.7481 15.9339 10.7482C15.9339 7.74829 13.502 5.31641 10.5021 5.31641Z" fill="url(#paint9_linear_5984_54722)" />
														<path d="M5.39529 15.8538C8.21403 18.6725 12.7841 18.6725 15.6029 15.8538C17.0122 14.4444 17.7169 12.5972 17.7169 10.75H3.28125C3.28125 12.5972 3.98594 14.4444 5.39529 15.8538Z" fill="url(#paint10_linear_5984_54722)" />
														<path d="M15.9311 10.9057L14.4537 9.42832C14.2973 9.23492 14.0699 9.09294 13.7863 9.05172L13.1477 8.95891C13.1226 8.934 11.3645 7.18305 11.3645 7.18305C10.899 6.60753 9.94828 6.67526 9.59729 7.38642L8.99031 8.61628C8.92689 8.74479 8.80427 8.83386 8.66246 8.85444L7.30524 9.05168C6.43765 9.17776 6.09124 10.2439 6.71901 10.8559C6.71901 10.8559 7.7672 11.9023 7.77572 11.9113C7.82439 11.9975 7.84346 12.0986 7.82633 12.1986L7.59446 13.5503C7.52438 13.959 7.69049 14.3216 7.96699 14.5473L9.50979 16.0902C9.83142 16.1495 10.1629 16.1809 10.5017 16.1809C13.4492 16.1808 15.8482 13.8331 15.9311 10.9057Z" fill="url(#paint11_linear_5984_54722)" />
														<path d="M11.4474 7.34351L12.0544 8.57337C12.1178 8.70188 12.2404 8.79095 12.3822 8.81154L13.7395 9.00877C14.607 9.13485 14.9535 10.201 14.3257 10.813L13.3436 11.7703C13.241 11.8703 13.1942 12.0144 13.2184 12.1556L13.4502 13.5074C13.5984 14.3715 12.6914 15.0304 11.9155 14.6224L10.7015 13.9842C10.5746 13.9175 10.4231 13.9175 10.2963 13.9842L9.08232 14.6224C8.30635 15.0304 7.3994 14.3715 7.54759 13.5074L7.77945 12.1556C7.80367 12.0144 7.75685 11.8703 7.65424 11.7703L6.67214 10.813C6.04436 10.201 6.39078 9.13485 7.25837 9.00877L8.61558 8.81154C8.7574 8.79095 8.87998 8.70188 8.94343 8.57337L9.55041 7.34351C9.93838 6.55737 11.0594 6.55737 11.4474 7.34351Z" fill="white" />
														<path d="M11.9154 14.6222L10.7015 13.9839C10.5746 13.9172 10.4232 13.9172 10.2963 13.9839L9.08245 14.6222C8.30647 15.0302 7.3994 14.3713 7.54755 13.507L7.77954 12.1555C7.80363 12.0141 7.75693 11.8699 7.65416 11.77L6.6721 10.8127C6.33457 10.4836 6.2787 10.0233 6.43402 9.64844L13.2221 11.9893C13.2103 12.0431 13.2087 12.0996 13.2183 12.1555L13.4502 13.507C13.5985 14.3713 12.6914 15.0302 11.9154 14.6222Z" fill="white" />
														<path d="M10.7026 13.9842L11.9166 14.6224C12.6925 15.0304 13.5995 14.3715 13.4513 13.5074L13.2195 12.1556C13.1952 12.0144 13.2421 11.8703 13.3447 11.7703L14.3268 10.813C14.9546 10.201 14.6081 9.13485 13.7405 9.00877L12.3833 8.81154C12.2415 8.79095 12.1189 8.70188 12.0555 8.57337L11.4485 7.34351C11.2545 6.95044 10.8773 6.75391 10.5 6.75391V13.9343C10.5695 13.9342 10.6391 13.9509 10.7026 13.9842Z" fill="white" />
														<path d="M9.08216 14.6222L10.296 13.9839C10.4229 13.9172 10.5744 13.9172 10.7012 13.9839L11.9151 14.6222C12.6911 15.0302 13.5981 14.3713 13.45 13.507L13.218 12.1555C13.1939 12.0141 13.2406 11.8699 13.3434 11.77L14.3254 10.8127C14.663 10.4836 14.7188 10.0233 14.5635 9.64844L7.77542 11.9893C7.7872 12.0431 7.78885 12.0996 7.77929 12.1555L7.5473 13.507C7.39907 14.3713 8.30615 15.0302 9.08216 14.6222Z" fill="white" />
														<path d="M13.8107 12.6094L12.791 13.629C11.9672 14.4528 10.8499 14.9156 9.6849 14.9156H8.70312V17.7429C11.0926 18.355 13.7344 17.7263 15.6049 15.8557C15.8514 15.6092 16.0759 15.349 16.2793 15.078L13.8107 12.6094Z" fill="url(#paint12_linear_5984_54722)" />
														<path d="M18.7047 16.3155L17.6513 17.1043C17.605 17.1368 17.5584 17.1687 17.5114 17.2C16.2467 18.0426 14.7191 18.3939 13.2064 18.2481C11.9413 18.1262 11.435 18.0371 9.80568 17.1574C9.52157 17.004 9.48241 16.6971 9.71806 16.4764L9.78262 16.4159C11.0463 15.9079 12.2593 15.2847 13.4067 14.5547C14.0655 14.1356 14.2454 13.2532 13.8069 12.6072C13.4077 12.0191 12.6294 11.8297 12.0029 12.1652C10.9272 12.7414 9.74231 13.0926 8.52143 13.1933C8.28817 13.2126 8.0535 13.2062 7.82073 13.182C7.08738 13.1056 5.31185 12.9331 4.71722 12.9872C4.71174 12.9877 4.70622 12.9882 4.70071 12.9888C3.14419 13.1328 1.86925 14.2862 1.56524 15.8197L1.54716 15.911C1.15409 17.8917 2.44197 19.8165 4.42318 20.2092L7.84428 20.8874L9.99917 21.2936C13.3815 21.9036 16.868 21.1507 19.6889 19.201L20.3735 18.6911C21.0329 18.2355 21.1955 17.3331 20.7368 16.675C20.2764 16.0146 19.3639 15.8533 18.7047 16.3155Z" fill="url(#paint13_linear_5984_54722)" />
														<path d="M3.06724 13.5703C2.30646 14.0795 1.75218 14.8782 1.56524 15.8211L1.54716 15.9123C1.15409 17.8931 2.44197 19.8179 4.42318 20.2106L7.84428 20.8888L9.99917 21.2951C10.3069 21.3506 10.6155 21.3945 10.9244 21.4275L3.06724 13.5703Z" fill="url(#paint14_linear_5984_54722)" />
														<path d="M13.809 12.6071C13.4098 12.0191 12.6315 11.8297 12.005 12.1652C10.8185 12.8007 9.49912 13.1626 8.14516 13.2165C8.12481 13.2173 8.10447 13.2182 8.08417 13.2192C5.79563 13.3338 5.5645 16.6262 7.81434 17.0607C7.97604 17.0919 8.1433 17.0758 8.29611 17.0144L9.78476 16.416C11.0484 15.908 12.2615 15.2848 13.4089 14.5548C14.0677 14.1356 14.2475 13.2532 13.809 12.6071Z" fill="url(#paint15_linear_5984_54722)" />
														<path d="M2.17638 20.4957H0.894394C0.400441 20.4957 0 20.0953 0 19.6014V13.6171C0 13.1231 0.400441 12.7227 0.894394 12.7227H2.17638C2.67034 12.7227 3.07078 13.1231 3.07078 13.6171V19.6014C3.07078 20.0953 2.67038 20.4957 2.17638 20.4957Z" fill="url(#paint16_linear_5984_54722)" />
													</g>
													<defs>
														<linearGradient id="paint0_linear_5984_54722" x1="12.7258" y1="16.173" x2="12.968" y2="17.3652" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFEEE6" />
															<stop offset="1" stop-color="#FFB09E" />
														</linearGradient>
														<linearGradient id="paint1_linear_5984_54722" x1="13.1515" y1="16.3163" x2="13.4902" y2="17.7781" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFEEE6" />
															<stop offset="1" stop-color="#FFB09E" />
														</linearGradient>
														<linearGradient id="paint2_linear_5984_54722" x1="14.1626" y1="17.2462" x2="14.6066" y2="18.2972" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFEEE6" />
															<stop offset="1" stop-color="#FFB09E" />
														</linearGradient>
														<linearGradient id="paint3_linear_5984_54722" x1="9.84916" y1="3.32692" x2="8.53655" y2="1.74127" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint4_linear_5984_54722" x1="18.1698" y1="10.5962" x2="19.8938" y2="11.8426" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint5_linear_5984_54722" x1="2.8261" y1="10.1657" x2="1.14153" y2="8.94779" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint6_linear_5984_54722" x1="4.93266" y1="5.38776" x2="2.98674" y2="3.52078" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint7_linear_5984_54722" x1="15.5459" y1="5.14404" x2="17.2932" y2="3.45622" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint8_linear_5984_54722" x1="3.23077" y1="9.78771" x2="19.2211" y2="15.5866" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint9_linear_5984_54722" x1="13.9779" y1="15.1785" x2="4.74789" y2="6.32304" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FEF0AE" />
															<stop offset="1" stop-color="#FAC600" />
														</linearGradient>
														<linearGradient id="paint10_linear_5984_54722" x1="12.5334" y1="14.8492" x2="14.0698" y2="16.7511" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FE9738" stop-opacity="0" />
															<stop offset="1" stop-color="#FE9738" />
														</linearGradient>
														<linearGradient id="paint11_linear_5984_54722" x1="14.1378" y1="15.8456" x2="8.44981" y2="6.47689" gradientUnits="userSpaceOnUse">
															<stop stop-color="#BE3F45" stop-opacity="0" />
															<stop offset="1" stop-color="#BE3F45" />
														</linearGradient>
														<linearGradient id="paint12_linear_5984_54722" x1="12.4912" y1="15.1923" x2="12.4912" y2="17.7678" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FE9738" stop-opacity="0" />
															<stop offset="1" stop-color="#FE9738" />
														</linearGradient>
														<linearGradient id="paint13_linear_5984_54722" x1="9.73667" y1="13.0482" x2="13.3397" y2="23.1475" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFEEE6" />
															<stop offset="1" stop-color="#FFB09E" />
														</linearGradient>
														<linearGradient id="paint14_linear_5984_54722" x1="7.34016" y1="17.4989" x2="1.15368" y2="17.4989" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFB09E" stop-opacity="0" />
															<stop offset="1" stop-color="#EC806B" />
														</linearGradient>
														<linearGradient id="paint15_linear_5984_54722" x1="10.1034" y1="14.3586" x2="12.3963" y2="16.6514" gradientUnits="userSpaceOnUse">
															<stop stop-color="#FFB09E" stop-opacity="0" />
															<stop offset="1" stop-color="#EC806B" />
														</linearGradient>
														<linearGradient id="paint16_linear_5984_54722" x1="0.418887" y1="16.6092" x2="2.90439" y2="16.6092" gradientUnits="userSpaceOnUse">
															<stop stop-color="#B3DAFE" />
															<stop offset="1" stop-color="#0182FC" />
														</linearGradient>
														<clipPath id="clip0_5984_54722">
															<rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
														</clipPath>
													</defs>
												</svg>
												<b className="ml0-5rem">Recommendation:</b></p>
											<p className="fs14px c15 lh1-5">{answer?.['aiResponse']?.[recommendationKey] || question[recommendationKey]}</p>
										</div>
									})()}
								</div>
							</div>
						</Fragment>
					})} </>}
			</>
		});
		return writeSections(module?.sections);
	};

	const getKPIContent = () => {
		return (
			<>
				<div className="bg52 br5px py0-5rem df aic jcsb mb1rem mt1rem">
					<p className="miw8rem c1 ml1rem">#KPI Name</p>
					<p className="miw8rem c1">KPI Description</p>
					<p className="miw8rem c1">KPI Calculation</p>
					<p className="miw8rem c1">KPI Metric</p>
				</div>
				{company?.keyPerformanceIndicators && company.keyPerformanceIndicators.map((kpi, idx) => (
					<tr key={`${idx}-kpi`}>
						<td className="miw8rem tal c15 fs12px fw500 fsi"><b>{kpi['title'] || '-'}</b></td>
						<td className="miw8rem tal c15 fs12px">{kpi['name'] || '-'}</td>
						<td className="miw8rem tal c15 fs12px">{kpi['calculation'] || '-'}</td>
						<td className="miw8rem tal c15 fs12px">{kpi['metric'] || '-'}</td>
					</tr>
				))}
			</>
		)
	};

	return <>
		{company && <>
			<table id="pdf" className={`op0 pen ma td250 dg gg2rem ${tab}`}></table>

			{/* Call To Action Buttons */}
			<div className="hideOnDownload mt2rem aic df">
				{(!user.firstName && tab == 'kpi-report') &&
					<div className="w100 df jcc aic fdc ">
						<p className="fs1-25rem mb1rem tac c15">Now let's view your 2-minute test report...</p>
						{user['role'] == 'Guest' && <>
							<button onClick={() => twoMinuteTestAIReport(module)}
								className="shadow bsbb jcc w100px w9rem df fdc aic fw500  px1rem c1 bg4 br19px cp registerButtonAnimation" style={{ width: '25%', paddingTop: '7px', paddingBottom: '7px' }}>
								View 2-Minute Test Report
							</button>
						</>}
					</div>
				}
				{tab.match(/two-minute-test-.*-report/) && (user?.['role'] == 'Guest') &&
					<div className="w100 df jcc aic">
						<a href={`/company/${company['_id']}`} style={{ width: '15%', paddingTop: '7px', paddingBottom: '7px' }}
							className={`shadow bsbb jcc df fdc aic fw500 my1rem px1rem c1 bg4 br19px cp registerButtonAnimation`}>
							Next
						</a>
					</div>
				}
				{/* Repository */}
				{module && module?.['type'] && module?.['type'] == "repository" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Business-Audit */}
				{module && module?.['type'] && module?.['type'].includes(`business-audit-${company?.['testType']}`) &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab(module['type'])} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Business Audit
						</button>
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Warranty-Disclosure */}
				{module && module?.['type'] && module?.['type'] == "warranty-disclosure" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
			</div>

			<table id="pdfContent" className="bsbb w0 h0 oh op0 pen db pr" style={{ width: "210mm" }}>
				{tab === "kpi-report"
					? <>
						<div className="w100 df aic jcsb pdfHeaderCustom">
							<div className="w50">
								<h2 className="tal c15 lh1 mt1rem fs1-25rem ">{module?.heading + ' Report'}</h2>
								<h3 className="fsi tal c15 fs1rem fw400 mb1rem">{company['cName']}</h3>
								<p className="mt3rem c15">Report Date: <span className="fw600 c15">{moment(new Date()).format('MMM DD, YYYY')}</span></p>
							</div>
							<div className="w50 df fdc jcsb aife">
								<img className="w80px h80px mb2rem" src={require("../../../../images/bonus.svg")} alt="bonusIcon" />
								<p className="c15 fs12px fw500 w100 tar">Bespoke KPI report for your company</p>
							</div>
						</div>
						{getKPIContent()}
					</>
					: <>
						{getOverviewContent()}
						{['repository-report', `business-audit-${company?.['testType']}-report`, 'warranty-disclosure-report', 'software-development-report', 'esg-report', `directors-questionnaire_${module?.['director']?.['_id']}`].includes(tab)
							? getAuditReportContent() :
							tab.includes("repository-sync-report") ? getSyncContent() :
								tab.includes("repository-audit-report") ? getAuditContent() :
									tab.match(/two-minute-test-.*-report/) ? getTwoMinuteTestContent() : getAuditReportContent()
						}
					</>
				}
			</table>
		</>}

		{/* Generating KPI Report */}
		<ModalWrapper modalType={'generatingKPI'}>
			<div className='tac'>
				<img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
				<h3 className='mb0-5rem c15'>Generating KPI Report</h3>
				<p className='c15 fs0-75rem mb1rem'>This may take few seconds.</p>
				<p className='mb1-5rem c15 fs12px px1rem op70'>We are prepare your <b className="fsi">BONUS KPI Report</b>, which suggests 10 useful business metrics for your business, how to calculate them, and your industry comparables.</p>
			</div>
		</ModalWrapper>
	</>;
} 